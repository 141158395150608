.TicketsList {
    padding: 8px 16px;
}
.TicketItem {
    width: 100%;
    border: 1px solid #c0c0cb;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.TicketSkeletonItem {
    width: 100%;
    border: 1px solid #c0c0cb;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.TicketItem:not(:last-child){
    margin-bottom: 10px;
}
.TicketAvailability{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: var(--dt-error);
    display: inline-block;
    margin-right: 4px;
}
.TicketAvailability.--available{
    background-color: var(--dt-success);
}
.ContentDetail{
    padding: 8px 16px;
}
.ContentDetail .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ContentDetail .RemoveTicketButton {
    font-weight: 600;
    font-size: 14px;
    border: 1px solid rgba(61, 60, 87, 0.15);
    height: 43px;
    width: 96px;
    border-radius: 43px;
    color: var(--dt-primary);
    background-color: white;
}
.VehicleSelectForTicket .alert-title{
    color: black;
}
.SelectModalTicketItem{
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    border-radius: 12px;
    border: 1px solid #C0C0CB;
}
.SelectModalTicketItem:not(:last-child){
    margin-bottom: 10px;
}

.Ticket.RadioRow {
    padding: 15px 20px;
    height: unset;
  }
.TicketSkeleton.RadioRow {
    padding: 15px 20px;
    height: unset;
    flex-direction: column;
}
.BuyTicketItem{
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    border-radius: 12px;
    border: 1px solid #C0C0CB;
}
.BuyTicketModal .content {
    padding: 16px;
    width: 100%;
}
.BuyTicketModal .content>.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}
.BuyTicketModal .content>.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
}

.BuyTicketModal .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95px;
    height: 95px;
    background: rgba(94, 83, 225, 0.13);
    border-radius: 20px;
    margin-bottom: 20px;
}