#terms-and-conditions{
    color: var(--dt-primary);
    h3{
        color: var(--dt-pink);
        padding-top: 12px;
        padding-bottom: 12px;
        font-weight: 600;
    }
    ul {
        list-style: none;
        color: var(--dt-primary);
        padding: 8px 0;
        li:before {
            content: "\2022";
            color: var(--dt-pink);
            font-size: 24px;
            margin-right: 8px;
        }
    }
}