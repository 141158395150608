.BusinessAccountsSlider{
  height: calc(100% - 55px);
  width: 100%;
  .swiper-slide{
    display: unset;
  }
}

.CarSlideContent ::part(background){
  --background: #FCFCFF !important; 
}
.CarSlideContent ::part(scroll){
  --background: #FCFCFF !important; 
}
.CarSlideContent {
  --background: #FCFCFF !important; 
}

.MyBusinessAccount {
  width: 100%;
  height: 100%;
}
.BusinessCode {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  .BusinessIconWrapper {
    width: 95px;
    min-height: 95px;
    background-color: rgba(94, 83, 225, .13);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
  }
  .BusinessCodeInput {
    width: 313px;
    height: 90px;
    border: 1px solid var(--dt-border);
    border-radius: 13px;
    margin: 50px 0;
    font-size: 34px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.09em;
    text-align: center;
    padding: 0px 16px;
  }
}

.BusinessInfo {
  padding: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  .InfoWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    height: 100%;
    .BusinessAccountLogo{
      width: 95px;
      height: 95px;
      margin-top: 50px;
    }
  }
  .BusinessInfoButtons {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 32px);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
.RemoveButton{
  position: absolute;
  left: 69.68%;
  right: 2.33%;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid rgba(61, 60, 87, 0.15);
  height: 43px;
  width: 96px;
  border-radius: 43px;
  color: var(--dt-primary);
  background-color: white;
}

.InfoArea{
  background: #FFFFFF;
  width: 100%;
  border: 1px solid #C0C0CB;
  border-radius: 12px;
  position: relative;
  padding: 17px 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  .CardInfos{
    display: flex;
    flex-direction: row;
    .CardInfoText{
      display: flex;
      flex-direction: column;
    }
    .CarBrandPosition{
      width: 57px;
      height: 57px;
      border: 1px solid #EAEAEE;
      border-radius: 8px;
    }
  }
  .CarInfos{
    display: flex;
    flex-direction: row;
    .CarInfoText{
      display: flex;
      flex-direction: column;
    }
    .CarBrandPosition{
      width: 57px;
      height: 57px;
      border: 1px solid #EAEAEE;
      border-radius: 8px;
    }
  }

}


.BusinessSliderModal {
  background-color: var(--dt-primary) !important;
  height: 100%;
  .BusinessSlideToolbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 16px;
  }
  .BusinessSlideTitle {
    padding-top: 20px;
    padding-bottom: 66px;
  }

  .BusinessSlider {
    height: calc(100% - 250px);
    width: 100vw;
    border-radius: 30px 30px 0 0;
    background-color: var(--dt-page-background);

    .SetBussinessCarWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 43px 16px;
      justify-content: flex-start;
      height: 100%;
      background-color: var(--dt-page-background);

      .CarList {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        background-color: var(--dt-page-background);
        margin-bottom: 50px;
      }

      .SetBussinesCarButtons {
        background-color: var(--dt-page-background);
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }

    .BusinessCardSlide {
      width: 100%;
      height: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .CardListWrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 30px;
        width: 100%;
      }

      .SetBusinesCardButtons {
        background-color: var(--dt-page-background);
        width: calc(100% - 32px);
        position: absolute;
        bottom: 0;
        margin-bottom: 50px;
      }
    }
  }
}

.BussinessCheckboxWrapper {
  cursor: pointer;
  position: relative;
}

.BussinessCheckbox {
  position: relative;
  display: flex;
  padding: 17px;
  border: 1px solid var(--dt-primary-300);
  border-radius: 12px;
  transition: all 0.15s ease-out;
  background-color: white;
}
.BussinessCheckbox::after {
  content: "";
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: var(--dt-primary-200);
  background-position: -40px center;
  background-repeat: no-repeat;
  background-size: 11px;
  transition: all 0.1s ease-out;
}

.BussinessCheckboxWrapper input[type="checkbox"] {
  cursor: pointer;
  position: absolute;
  inset: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}
.BussinessCheckboxWrapper input[type="checkbox"]:checked + .BussinessCheckbox {
  border-color: var(--dt-pink);
}
.BussinessCheckboxWrapper
  input[type="checkbox"]:checked
  + .BussinessCheckbox::after {
  background-color: var(--dt-pink);
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.7879 0.398744C11.2778 0.912796 11.2582 1.72663 10.7441 2.21649L5.40642 7.303C4.87669 7.8078 4.04395 7.8078 3.51422 7.303L0.398744 4.33414C-0.115309 3.84428 -0.134919 3.03044 0.354943 2.51639C0.844804 2.00234 1.65864 1.98273 2.17269 2.47259L4.46032 4.65257L8.97017 0.354943C9.48423 -0.134919 10.2981 -0.115309 10.7879 0.398744Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: center;
}

.BusinessRadio {
  position: relative;
  display: flex;
  padding: 17px;
  border: 1px solid var(--dt-primary-300);
  border-radius: 12px;
  transition: all 0.15s ease-out;
  background-color: white;
}
.BusinessRadio::after {
  content: "";
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  height: 20px;
  width: 20px;
  border: 1px solid var(--dt-primary-300);
  border-radius: 50%;
  background-color: #fff;
  background-position: -40px center;
  background-repeat: no-repeat;
  background-size: 11px;
  transition: all 0.1s ease-out;
}

.BusinessRadioWrapper{
  width: 100%;
}

.BusinessRadioWrapper input[type="radio"] {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  z-index: 2;
}
.BusinessRadioWrapper input[type="radio"]:checked + .BusinessRadio {
  border-color: var(--dt-pink);
}
.BusinessRadioWrapper input[type="radio"]:checked + .BusinessRadio::after {
  border-radius: 50%;
  background-color: var(--dt-pink);
  background-position: center;
}

.BusinessRadioContent {
  display: flex;
  flex-direction: column;
}

.BusinessAccounts {
  width: 100%;
  height: calc(100% - 55px);
  padding: 16px;
  .Accounts {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: flex-start;
    .Account {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 89px;
      border: 1px solid #c0c0cb;
      border-radius: 12px;
      padding: 16px;
      .CompanyLogo{
        width: 57px;
        height: 57px;
      }
    }
  }
  .AddAccountButton {
    position: absolute;
    bottom: 44px;
    width: calc(100% - 32px) !important;
    height: 55px;
    background-color: var(--dt-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 55px;
    gap: 9px;
  }
}
