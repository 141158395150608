.CardSlide{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: margin .5s !important;
    .SlideWrapper{
        height: 335px;
        width: 200px;
        display: flex;
        .Slide{
            transform: rotate(-90deg);
        }
    }
    .Drifter{
        transform: rotate(90deg);
    }
}

.CardSwiper{
    height: 600px !important;
}

.CardSwiper .swiper-pagination-bullets {
    bottom: 0px;
}

.EditButton{
    cursor: pointer;
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50px;
    width: 44px;
    height: 44px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    background-color: #fff;
}

.AddPaymentMethodButtonsWrapper{
    margin-top: 20px;
    .OrDivider{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        .line{
            display: inline-flex;
            border-bottom: 1px solid #000000;
            opacity: 0.1;
            width: 119px;
        }
        .OrDividerText{
            text-transform: uppercase;
            color: rgba(61, 60, 87, 0.75);
            font-weight: 700;
            font-size: 14px;
        }
    }
    
    .ButtonsWrapper{
        display: flex;
        justify-content: center;
        flex-direction: row;
        gap: 3%;
        .AddNewPaymentMethodButton{
            width: 92px;
            height: 63px;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.NFCModal{
    .Content{
        background: var(--dt-primary)  !important;
        width: 100%;
        height: 100%;
        .NFCPhone{
            position: absolute;
            top: 410px;
            z-index: 1000;
        }
        .CardIcon{
            position: absolute;
            top: 364px;
            z-index: 500;
        }

        .circles{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 390px;
            z-index: 1;
            .circle1{
                position: absolute;
                display: inline-flex;
                border: 1px solid var(--dt-pink);
                animation: scaleAnim 2s infinite;
                width: 70px;
                height: 70px;
                border-radius: 70px;
            }
            .circle2{
                position: absolute;
                display: inline-flex;
                border: 1px solid var(--dt-pink);
                animation: scaleAnim 2s infinite;
                width: 100px;
                height: 100px;
                border-radius: 100px;
            }
        }

    }
}
@keyframes scaleAnim {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(3);
        opacity: 0;
    }
  }
@keyframes scaleAnim {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(3);
        opacity: 0;
    }
  }

