.App-payment {
  .rccs {
    width: 100% !important;
  }

  .rccs__card {
    height: 235px !important;
    width: 100% !important;
  }

  .rccs__card--unknown > div {
    background: linear-gradient(137.76deg, #B11D94 2.23%, #353355 162.94%), #353355;
    box-shadow: none;
  }

  .rccs__expiry__valid {
    display: none;
  }

  .deactivateClass {
    display: none;
  }

  .activateClass {
    display: block;
  }

  a {
    color: #666;
  }

  h1 {
    font-weight: bold;
    margin: 0 0 10px;
  }

  h4 {
    margin-bottom: 30px;
  }

  h1,
  h2,
  h4 {
    text-align: center;
  }

  .App-payment {
    padding: 16px;
  }

  .buttons {
    margin: 15px 0;
  }

  .form-actions {
    margin-top: 15px;
  }

  .App-cards {
    margin: 0 auto;
    max-width: 1280px;
    text-align: center;
  }

  .App-cards-list {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .App-cards-list > * {
    transform: scale(0.8);
    margin-bottom: 30px !important;
  }

  .App-highlight {
    font-family: serif;
    margin: 15px auto 0;
    max-width: 300px;
  }

  .App-highlight > div {
    padding-left: 40px;
  }

  .App-badges {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .App-badges a {
    display: block;
  }

  .App-badges a + a {
    margin-top: 18px;
  }

  .github__btn {
    align-items: center;
    background-color: #ccc;
    border-radius: 4px;
    color: #444;
    display: flex;
    line-height: 1;
    padding: 8px 12px;
  }

  .github__btn:hover {
    color: #444;
    text-decoration: none;
  }

  .github__btn img {
    display: inline-block;
    height: 28px;
    margin-right: 8px;
  }

  .App-credits {
    background-color: #000;
    color: #fff;
    line-height: 50px;
    text-align: center;
  }

  .App-credits a {
    color: #fff;
    font-weight: bold;
  }

  .title {
    line-height: 1.5rem;
    text-align: center;
    width: 100%;
  }

  .border-label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;

    letter-spacing: -0.02em;

    color: #635C5C;
    margin-left: 9px;
  }

  .Baslik {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */

    text-align: center;
    letter-spacing: -0.01em;

    /* Primary/500 */

    color: #353355;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .border-input {
    background: #FFFFFF;
    /* Primary/300 */

    border: 1px solid #C0C0CB;
    border-radius: 12px;
    height: 55px;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 16px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 7px;

    /* Primary/500 */

    color: #353355;

    opacity: 0.6;

    &.error {
      border-color: red;
    }
  }

  .border-input::placeholder {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    /* Primary/500 */

    color: #353355;

    opacity: 0.6;
  }

  .icon {
    font-size: 1rem;
    fill: var(--ion-color-dark);
  }

  .hr {
    margin-top: 20px;
    margin-bottom: -12px;
    width: 100% !important;
  }

  .hr-left {
    position: relative;
    float: left;
    left: 9px;
    width: 35%;
    opacity: 0.1;
    border: 1px solid var(--ion-color-dark);

  }

  .hr-right {
    position: relative;
    height: 2px;
    line-height: 40px;
    width: 35%;
    opacity: 0.1;
    background-color: var(--ion-color-dark);
    float: left;
    right: 9px;
  }

  .footer-button {
    padding: 12px;
  }

  .hr-or {
    position: relative;
    width: 30%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    color: rgba(61, 60, 87, 0.75);

    opacity: 0.65;
    float: left;
    top: -14px;
    margin: 0;
  }

  .add-button {
    border-radius: 1000px;
    background-color: #353355;
    height: 52px;
    width: 100%;
    &.activateClass {
      color: #fff;
      width: 100%;
    }

    &.deactivateClass {
      opacity: 0.2;
      width: 100%;
    }
  }

  @media screen and (min-width: 600px) {
    .App-badges {
      flex-direction: row;
    }

    .App-badges a + a {
      margin-top: 0;
      margin-left: 18px;
    }
  }
}
