.CircularProgressBar {
  position: relative;
}

.CircularProgressBarContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.CircularProgressBarContent span {
  text-align: center;
  line-height: 1;
}

.CircularProgressBarPercentage {
  color: #353355;
  font-size: 32px;
  font-weight: 600;
}
.CircularProgressBarPercentage span {
  font-weight: 400;
}

.CircularProgressBarText {
  margin-top: 4px;
  color: #353355;
  font-size: 12px;
  font-weight: 600;
}
