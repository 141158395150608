.alert-wrapper {
    --background: #fff;
}

.alert-wrapper .alert-message {
    color: #000 !important;
}

.alert-wrapper .alert-button-group {
    color: #000 !important;
}
.alert-radio-label{
    color: #000 !important;
}
[aria-checked=true] .alert-radio-label{
    color: #3880ff !important;
}
.AccountSelectorAlert .alert-head h2 {
    color: #000 !important;
}
.alert-radio-group{
    border-top: 0.55px solid rgba(0, 0, 0, 0.2) !important;
    border-bottom: 0.55px solid rgba(0, 0, 0, 0.2) !important;
}
.VehicleSelectorAlert .alert-head h2 {
    color: #000 !important;
}
