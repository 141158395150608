#privacy-and-policy {
    h1{
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        color: var(--dt-primary);
        text-transform: capitalize;
        padding: 16px 0;
    }
    h2{
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        color: var(--dt-pink);
        padding: 10px 0;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: var(--dt-primary);
        padding: 8px 0;
        span.red-highlight {
                color: red;
            }
    }
    ul {
        list-style: none;
        color: var(--dt-primary);
        padding: 8px 0;
        li:before {
            content: "\2022";
            color: var(--dt-pink);
            font-size: 24px;
            margin-right: 8px;
        }
    }
    table{
        padding: 8px 0;
        tr{
            td:first-child{
                font-weight: 500;
            }
        }
        td{
            color: var(--dt-primary);
            padding: 8px;
        }
    }

}