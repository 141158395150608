/********** Range Input Styles **********/
/*Range Reset*/
.car-range {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  width: 300px;
}

/* Removes default focus */
.car-range:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
.car-range::-webkit-slider-runnable-track {
  background: rgba(177, 29, 148, 1);
  height: 55px;
  border-radius: 55px;
}

/* slider thumb */
.car-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: url("../../../../assets/icons/range-pin.svg") 50% 50% no-repeat;
  height: 55px;
  width: 72px;
}

/******** Firefox styles ********/
/* slider track */
.car-range::-moz-range-track {
  background: rgba(177, 29, 148, 1);
  height: 55px;
}

/* slider thumb */
.car-range::-moz-range-thumb {
  background: url("../../../../assets/icons/range-pin.svg") no-repeat;
  height: 55px;
}

.divider-bottom {
  border-bottom: 1px solid rgba(61, 60, 87, 0.15);
}

.FinishingModal {
  --border-radius: 35px 35px 0 0;
}
.FinishingModal::part(backdrop) {
  --backdrop-opacity: 0.6;
  background-color: #353355;
}
.FinishingModal ::part(background) {
  --background: #353355;
  background: #353355;
}
.parkingLights {
  animation: parkingLights 2s ease-out forwards;
}

.border-button-white {
  border: 1px solid white;
}

.StartChargeButton{
  width: 177px;
  height: 56px;
  background-color: var(--dt-green);
  border-radius: 56px;
  font-weight: 600;
  font-size: 16;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
  margin-top: 16px;
}

.overstayCounter {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.overstayCounter ._half-circular-progress {
  display: inline;
  vertical-align: initial;
  position: absolute;
}

.overstayCounter ._half-circular-progress text {
  display: none;
}
