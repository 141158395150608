.carParkImage {
  position: relative;
}
.carParkImage .parkingLights {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  height: auto;
  width: 50px;
  opacity: 0;
  z-index: 2;
}
.carParkImageParking .parkingLights {
  animation: parkingLights 3s ease-out forwards;
}
@keyframes parkingLights {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/********** Range Input Styles **********/
/*Range Reset*/
.car-range {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  width: 300px;
}

/* Removes default focus */
.car-range:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
.car-range::-webkit-slider-runnable-track {
  background: rgba(177, 29, 148, 1);
  height: 55px;
  border-radius: 55px;
}



/* slider thumb */
.car-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: url("../../../../assets/icons/range-pin.svg") 50% 50% no-repeat !important;
  height: 55px;
  width: 72px;
}

/******** Firefox styles ********/
/* slider track */
.car-range::-moz-range-track {
  background: rgba(177, 29, 148, 1);
  height: 55px;
}

/* slider thumb */
.car-range::-moz-range-thumb {
  background: url("../../../../assets/icons/range-pin.svg") no-repeat !important;
  height: 55px;
}

.car {
  position: relative;
}

.bg {
  background-color: rgba(61, 60, 87, 0.2);
}
.carRangeBackground,
.carRangeThumb {
  display: none;
}

/* .car-range {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  opacity: 0;
  z-index: 2;
} */
