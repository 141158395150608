.SearchModal ::part(background),
.FilterModal ::part(background) {
  --background: #fff;
  background: #fff;
}

.SearchWrapper,
.SearchResultsWrapper,
.FilterWrapper {
  width: 93%;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.FilterWrapper{
  padding: 25px;
}


.SearchResultsRow {
  display: flex;
  flex-direction: column;
  transition: all 0.15s ease-out;
}
.SearchResultsRow:not(:first-of-type) {
  margin-top: 40px;
}
.SearchResultsSliderWrapper + .SearchResultsRow {
  margin-top: 0;
}

.SearchWrapper.searching .SearchResultsRow.-recent-search {
  display: none;
}
.SearchWrapper.searching .SearchResultsRow.-recent-search .SearchTitle {
  margin-bottom: 0;
}

.SearchTitle {
  margin: 0 10px 30px 0;
  color: var(--dt-pink);
  font-size: 14px;
  font-weight: 600;
}

.Results {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Results .Result {
  cursor: pointer;
  position: relative;
  min-height: 60px;
  padding: 0 0 18px 30px;
  border-bottom: 1px solid rgba(61, 60, 87, 0.15);
  animation: slideUp 0.1s ease-out forwards;
}
.Results .Result:not(:first-of-type) {
  padding-top: 18px;
}

.Result .ResultIcon {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.Result:not(:first-of-type) .ResultIcon {
  top: 18px;
}
.Result .ResultIcon.-park_and_charge {
  height: 18px;
  width: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='563' height='512' viewBox='0 0 563 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M246.513 376.123H304.363V304.24H333.625C382.394 304.24 419.392 272.331 419.392 224.292C419.392 175.201 382.394 144.694 333.625 144.694H246.513V376.123ZM304.363 251.643V197.292H333.961C350.106 197.292 360.532 208.162 360.532 224.292C360.532 240.772 350.106 251.643 333.961 251.643H304.363Z' fill='%23B11D94'/%3E%3Cpath d='M141.877 235.821V195.367H115.61L115.608 146.662C115.608 140.85 110.983 136.139 105.276 136.139C99.5681 136.139 94.9427 140.85 94.9427 146.662V195.367H46.9455L46.9434 146.662C46.9434 140.85 42.3179 136.139 36.6105 136.139C30.9031 136.139 26.2777 140.85 26.2777 146.662V195.367H0.0126953V235.821C0.0126953 273.424 29.8175 304.478 65.2963 307.736C88.8552 424.178 190.304 512 311.51 512C450.114 512 562.881 397.156 562.881 255.998C562.881 114.841 450.11 0 311.506 0C236.394 0 168.972 33.8037 122.883 87.1732C118.411 92.3519 120.432 100.268 126.705 103.001C131.017 104.88 136.035 103.641 139.128 100.097C181.422 51.6334 242.982 20.9851 311.506 20.9851C438.747 20.9851 542.269 126.413 542.269 255.998C542.269 385.583 438.747 491.011 311.506 491.011C201.25 491.011 108.803 411.854 86.0872 306.41C117.988 299.346 141.877 270.426 141.877 235.821Z' fill='%23B11D94'/%3E%3C/svg%3E%0A");
}
.Result .ResultIcon.-charging {
  height: 18px;
  width: 11px;
  background-image: url("data:image/svg+xml,%3Csvg width='313' height='512' viewBox='0 0 313 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M310.451 226.306C307.097 220.184 300.387 216.783 293.678 216.102L196.395 210.661L181.635 18.8374C180.964 9.31425 174.255 1.83178 164.862 0.471329C156.14 -1.56934 147.418 3.19223 143.393 11.3549L1.82973 301.811C-2.86668 312.694 1.82973 324.938 11.8935 329.7C14.5771 331.06 17.9317 331.741 21.2863 331.741L111.189 326.299L117.898 492.954C118.569 501.797 124.607 509.959 133.329 512H137.355C144.735 512 151.444 508.599 155.469 502.477L309.78 246.713C313.806 240.591 313.806 232.428 310.451 226.306Z' fill='%2334D298'/%3E%3C/svg%3E%0A");
}
.Result .ResultIcon.-parking {
  height: 18px;
  width: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='513' height='512' viewBox='0 0 513 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M412.636 0H99.3639C44.8518 0 0 44.8518 0 99.3639V412.636C0 467.148 44.8518 512 99.3639 512H412.636C467.148 512 512 467.148 512 412.636V100.054C512.69 44.8517 467.838 0 412.636 0ZM385.035 242.199C380.205 255.31 372.615 267.04 362.954 276.701C353.294 287.051 341.563 294.642 327.073 300.852C313.272 307.062 296.712 309.822 279.461 309.822H214.598V418.846H143.526V94.5337H280.151C298.092 94.5337 314.652 97.9838 328.453 104.194C342.253 110.404 354.674 118.685 363.644 129.035C373.305 139.385 380.205 151.116 385.035 163.536C389.865 175.957 392.625 189.067 392.625 202.178C392.625 215.978 389.865 229.089 385.035 242.199Z' fill='%235E53E1'/%3E%3Cpath d='M279.44 163.548H214.578V242.211H279.44C284.96 242.211 290.481 240.831 295.311 238.761C300.141 236.691 304.971 233.931 308.421 229.791C311.872 226.34 315.322 221.51 317.392 216.68C319.462 211.85 320.842 206.33 320.842 200.809C320.842 189.769 317.392 180.799 309.111 173.208C302.211 166.998 291.861 163.548 279.44 163.548Z' fill='%235E53E1'/%3E%3Cpath fill-rule='evenodd' clipRule='evenodd' d='M385.035 242.199C380.205 255.31 372.615 267.04 362.954 276.701C353.294 287.051 341.563 294.642 327.073 300.852C313.272 307.062 296.712 309.822 279.461 309.822H214.598V418.846H143.526V94.5337H280.151C298.092 94.5337 314.652 97.9838 328.453 104.194C342.253 110.404 354.674 118.685 363.644 129.035C373.305 139.385 380.205 151.116 385.035 163.536C389.865 175.957 392.625 189.067 392.625 202.178C392.625 215.978 389.865 229.089 385.035 242.199ZM214.578 163.548H279.44C291.861 163.548 302.211 166.998 309.111 173.208C317.392 180.799 320.842 189.769 320.842 200.809C320.842 206.33 319.462 211.85 317.392 216.68C315.322 221.51 311.872 226.34 308.421 229.791C304.971 233.931 300.141 236.691 295.311 238.761C290.481 240.831 284.96 242.211 279.44 242.211H214.578V163.548Z' fill='white'/%3E%3C/svg%3E%0A");
}
.Result .ResultIcon.-fast_charging {
  height: 18px;
  width: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='572' height='512' viewBox='0 0 572 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M430.554 316.512C428.382 319.231 424 317.7 423.993 314.22L423.773 201.392C423.753 191.235 415.514 183.012 405.357 183.012H334.709C331.622 183.012 329.904 179.441 331.832 177.029L472.169 1.39129C474.341 -1.32682 478.723 0.20409 478.73 3.68329L478.95 116.501C478.97 126.658 487.209 134.882 497.366 134.882H568.014C571.101 134.882 572.819 138.452 570.891 140.864L430.554 316.512Z' fill='%2300C2CE'/%3E%3Cpath d='M152.82 510.738C150.581 513.29 146.375 511.712 146.368 508.317L145.998 335.723C145.976 325.567 137.737 317.346 127.582 317.346H3.69042C0.523616 317.346 -1.1668 313.614 0.921721 311.234L225.04 55.7847C227.279 53.2329 231.485 54.8112 231.492 58.2059L231.862 230.785C231.883 240.941 240.122 249.162 250.278 249.162H374.169C377.336 249.162 379.026 252.894 376.938 255.274L152.82 510.738Z' fill='%2300C2CE'/%3E%3C/svg%3E%0A");
}
.Result .ResultIcon.-address {
  height: 18px;
  width: 13px;
  background-image: url("data:image/svg+xml,%3Csvg width='401' height='499' viewBox='0 0 401 499' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clip-rule='evenodd' d='M179.358 489.741C191.087 501.341 209.453 501.341 221.182 489.741C272.507 438.989 400.541 302.17 400.541 200.27C400.541 72.8256 309.507 0 200.27 0C91.032 0 0 72.8256 0 200.27C0 302.17 128.033 438.989 179.358 489.741Z' fill='%23C0C0CB'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M200.273 291.293C250.549 291.293 291.305 250.537 291.305 200.261C291.305 149.986 250.549 109.229 200.273 109.229C149.998 109.229 109.241 149.986 109.241 200.261C109.241 250.537 149.998 291.293 200.273 291.293Z' fill='white'/%3E%3C/svg%3E%0A");
}

.Result .ResultTitle {
  color: var(--dt-primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  transition: all 0.1s ease-out;
}
.Result .ResultZoneCode {
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  transition: all 0.1s ease-out;
  background-color: var(--dt-pink);
  padding: 1px 3px;
  border-radius: 3px;
  height: fit-content;
}

.searching .Result .ResultTitle {
  color: rgba(61, 60, 87, 0.75);
  font-weight: 400;
}
.Result .ResultTitle .highlighted {
  color: var(--dt-primary);
  font-weight: 700;
}

.Result .ResultDistance,
.Result .ResultAddress {
  margin: 8px 0 0;
  color: var(--dt-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

/* Near by me Slider */
.SearchResultsSliderWrapper {
  display: flex;
  flex-direction: column;
}
.searching .SearchResultsSliderWrapper {
  display: none;
}

.SearchResultsSlider {
  margin: 0 0 50px;
  overflow: visible;
}

.SearchResultsSlider .SearchResultsSlide {
  display: flex;
  justify-content: flex-start;
  height: 128px;
  width: 304px !important;
  padding: 22px;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.1);

}

.SearchResultsSlide .ResultsSliderIcon {
  flex-shrink: 0;
  margin: 0 12px auto 0;
}

.SearchResultsSlide .ResultsSliderContent {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}
.SearchResultsSlide .ResultsSliderTitle {
  color: var(--dt-primary);
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
.SearchResultsSlide .ResultsSliderAddres {
  margin: 6px 0 0;
  color: var(--dt-primary);
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.SearchResultsSlide .ResultsSliderDistance {
  margin: 24px 0 0;
  color: var(--dt-primary);
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.SearchResultsSlide .ResultsSliderCTA {
  height: 14px;
  width: 7px;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 14px 0 auto 12px;
  transition: all 0.1s ease-out;
}
.SearchResultsSlide:active .ResultsSliderCTA {
  transform: translateX(2px);
}

.SearchResultsSkeletons {
  display: none;
  flex-direction: column;
  grid-gap: 10px;
  width: 100%;
}
.SearchResultsSkeletons.active {
  display: flex;
}
.SearchResultsSkeleton {
  position: relative;
  height: 60px;
  width: 100%;
  border-radius: 12px;
}
.SearchResultsSkeleton::before {
  content: "";
  display: block;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  background-image: linear-gradient(
    90deg,
    #ececec 0px,
    #f4f4f4 40px,
    #ececec 80px
  );
  background-size: cover;
  animation: shine-loading-image 1s infinite ease-out;
}
.SearchResultsSkeleton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 38px;
  display: block;
  height: 24px;
  width: calc(100% - 38px);
  border-radius: 4px;
  background-image: linear-gradient(
    90deg,
    #ececec 0px,
    #f4f4f4 40px,
    #ececec 80px
  );
  background-size: cover;
  animation: shine-loading-image 1s infinite ease-out;
}

.SearchResultsSliderSkeletons {
  display: flex;
  grid-gap: 30px;
  margin-bottom: 50px;
  width: 100%;
  overflow: hidden;
}
.SearchResultsSliderSkeleton {
  flex-shrink: 0;
  height: 128px;
  width: 300px;
  border-radius: 14px;
  background-image: linear-gradient(
    90deg,
    #ececec 0px,
    #f4f4f4 40px,
    #ececec 80px
  );
  background-size: cover;
  animation: shine-loading-image 1s infinite ease-out;
}

@keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }
  40%,
  100% {
    background-position: 208px;
  }
}

/* Search Results Fab */
.SearchResultsFab {
  position: fixed;
  right: 15px;
  bottom: 24px;
  z-index: 1;
}

.SearchResultsFabToggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 8px;
  height: 56px;
  width: 98px;
  border: 1px solid rgba(61, 60, 87, 0.15);
  border-radius: 56px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.70153 7.11012C0.347966 6.68584 0.40529 6.05527 0.829567 5.70171L6.70153 0.808408C7.14654 0.437564 7.79296 0.43756 8.23797 0.808408L14.1099 5.70171C14.5342 6.05527 14.5915 6.68584 14.238 7.11012C13.8844 7.53439 13.2538 7.59172 12.8296 7.23815L7.46975 2.77164L2.10994 7.23815C1.68566 7.59172 1.05509 7.53439 0.70153 7.11012Z' fill='%23353355'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 18px) 22px;
  transition: all 0.15s ease-out;
}
.FilterModal .SearchResultsFabToggle {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.2379 0.890114C14.5915 1.31439 14.5342 1.94496 14.1099 2.29852L8.23792 7.19182C7.79291 7.56267 7.1465 7.56267 6.70148 7.19182L0.829517 2.29852C0.40524 1.94496 0.347917 1.31439 0.70148 0.890114C1.05504 0.465837 1.68561 0.408513 2.10989 0.762077L7.4697 5.22859L12.8295 0.762077C13.2538 0.408513 13.8844 0.465837 14.2379 0.890114Z' fill='%23353355'/%3E%3C/svg%3E%0A");
}

.SearchResultsFabItem {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  background-color: #fff;
  border: 1px solid rgba(61, 60, 87, 0.15);
  border-radius: 56px;
  transform: scale(0) translateY(20px);
  transition: all 0.15s cubic-bezier(0.39, 0.43, 0.49, 0.92);
  z-index: -1;
}

.SearchResultsFabIcon {
  height: 38px;
  width: 38px;
  border-radius: 38px;
  background-color: #c0c0cb;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.1s ease-out;
}
.SearchResultsFabItem.-park_and_charge .SearchResultsFabIcon,
.SearchResultsFabToggle.-park_and_charge .SearchResultsFabIcon {
  background-color: #b11d94;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='22' viewBox='0 0 26 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6543 16.1238H14.2169V13.0576H15.5131C17.6734 13.0576 19.3123 11.6964 19.3123 9.64728C19.3123 7.55325 17.6734 6.25195 15.5131 6.25195H11.6543V16.1238ZM14.2169 10.814V8.49556H15.528C16.2431 8.49556 16.705 8.95924 16.705 9.64728C16.705 10.3503 16.2431 10.814 15.528 10.814H14.2169Z' fill='white'/%3E%3Cpath d='M7.01952 10.1397V8.41406H5.85595L5.85586 6.33657C5.85586 6.08863 5.65096 5.8877 5.39814 5.8877C5.14532 5.8877 4.94043 6.08863 4.94043 6.33657V8.41406H2.81396L2.81386 6.33657C2.81386 6.08863 2.60897 5.8877 2.35615 5.8877C2.10333 5.8877 1.89844 6.08863 1.89844 6.33657V8.41406H0.735352V10.1397C0.735352 11.7595 2.00961 13.0873 3.62872 13.2116C4.67414 18.1763 9.16685 21.9201 14.5342 21.9201C20.674 21.9201 25.6692 17.0212 25.6692 11C25.6692 4.97876 20.6738 0.0800781 14.5341 0.0800781C11.2156 0.0800781 8.23603 1.5144 6.19488 3.78053C5.99093 4.00695 6.0804 4.36146 6.36141 4.47936C6.54571 4.55668 6.75809 4.5048 6.89217 4.35659C8.76584 2.28541 11.4954 0.975223 14.5341 0.975223C20.1705 0.975223 24.7561 5.47239 24.7561 11C24.7561 16.5276 20.1705 21.0248 14.5341 21.0248C9.65013 21.0248 5.5551 17.6483 4.54877 13.1506C5.96157 12.8491 7.01952 11.6156 7.01952 10.1397Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: 5px center;
}
.SearchResultsFabItem.-charging .SearchResultsFabIcon,
.SearchResultsFabToggle.-charging .SearchResultsFabIcon {
  background-color: #34d298;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.67928 15.6207C4.67952 15.7466 4.83816 15.8021 4.91678 15.7037L11.827 7.05477C11.8967 6.96745 11.8346 6.83821 11.7228 6.83821H7.9987C7.63102 6.83821 7.33275 6.54052 7.33204 6.17284L7.32073 0.379283C7.32048 0.253337 7.16185 0.197918 7.08323 0.296314L0.173038 8.94471C0.103272 9.03202 0.16544 9.16127 0.277203 9.16127H4.0013C4.36898 9.16127 4.66725 9.45896 4.66797 9.82664L4.67928 15.6207Z' fill='%23FFFEFE'/%3E%3C/svg%3E%0A");
}
.SearchResultsFabItem.-parking .SearchResultsFabIcon,
.SearchResultsFabToggle.-parking .SearchResultsFabIcon {
  background-color: #5e53e1;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 13H3.3463V8.96212H5.03891C7.85992 8.96212 10 7.1697 10 4.47121C10 1.71364 7.85992 0 5.03891 0H0V13ZM3.3463 6.00758V2.95454H5.05836C5.99222 2.95454 6.59533 3.56515 6.59533 4.47121C6.59533 5.39697 5.99222 6.00758 5.05836 6.00758H3.3463Z' fill='white'/%3E%3C/svg%3E%0A");
}
.SearchResultsFabItem.-fast_charging .SearchResultsFabIcon,
.SearchResultsFabToggle.-fast_charging .SearchResultsFabIcon {
  background-color: #00c2ce;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.2985 18.2781C5.29876 18.401 5.45102 18.4581 5.53206 18.3657L13.6451 9.11794C13.7207 9.03176 13.6595 8.89667 13.5449 8.89667H9.06003C8.6924 8.89667 8.39416 8.59906 8.39337 8.23143L8.37998 1.98407C8.37972 1.86119 8.22746 1.80405 8.14642 1.89643L0.0333662 11.1437C-0.0422381 11.2298 0.0189549 11.3649 0.133593 11.3649H4.61844C4.98607 11.3649 5.28432 11.6625 5.28511 12.0302L5.2985 18.2781Z' fill='%23FFFEFE'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.3481 11.0621C15.3485 11.251 15.5865 11.3342 15.7044 11.1866L20.5797 5.08457C20.6843 4.9536 20.5911 4.75973 20.4234 4.75973H17.5388C17.4285 4.75973 17.3391 4.67043 17.3388 4.56012L17.3303 0.200002C17.33 0.0110818 17.092 -0.0720462 16.9741 0.0755467L12.0988 6.17719C11.9941 6.30816 12.0874 6.50203 12.255 6.50203H15.1396C15.2499 6.50203 15.3394 6.59134 15.3396 6.70164L15.3481 11.0621Z' fill='%23FFFEFE'/%3E%3C/svg%3E%0A");
}

.SearchResultsFabToggle.-park_and_charge
  + .SearchResultsFabItems
  .-park_and_charge {
  position: absolute;
  transform: scale(0);
}
.SearchResultsFabToggle.-parking + .SearchResultsFabItems .-parking {
  position: absolute;
  transform: scale(0);
}
.SearchResultsFabToggle.-charging + .SearchResultsFabItems .-charging {
  position: absolute;
  transform: scale(0);
}

.SearchResultsFabItems {
  position: absolute;
  top: -9px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 9px;
  transition: all 0.15s ease-out;
  z-index: -1;
}

.SearchResultsFabExpand {
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 55%;
  border-radius: 50%;
  z-index: 2;
}

.SearchResultsFab.expanded .SearchResultsFabItems {
  transform: translateY(-100%);
}
.SearchResultsFab.expanded .SearchResultsFabItem {
  height: 56px;
  transform: scale(1);
}

/* Filters */
.FilterTitle {
  margin: 0 0 16px;
  color: var(--dt-primary);
  font-size: 16px;
  font-weight: 600;
}
.FilterTitle.-secondary {
  color: var(--dt-pink);
  font-size: 14px;
}
.FilterRow{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0 16px;
}

.FilterRow:not(:last-of-type) {
  margin-bottom: 24px;
}
.FilterItems{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}
.FilterItem {
  cursor: pointer;
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-align: left;
  color: var(--dt-primary);
  font-size: 14px;
  font-weight: 400;
}

.FilterItem > span {
  margin-left: 4px;
  font-weight: 600;
}

.FilterItem .ToggleSwitch {
  margin-left: auto;
}

.ParkingRateItem{
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #C0C0CB;
  width: 75px;
  height: 42px;
  border-radius: 14px;
  color: #C0C0CB;
  font-size: 14px;
  font-weight: 500;
}

.ParkingRateItem.All{
  width: 102px;
  height: 42px;
}

.ParkingRateItem.Active{
  background-color: #3D3C57;
  color: white;
  border: 1px solid #3D3C57;
}

.OperatorList{
  width: 100%;
  height: 100%;
}
.OperatorItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-weight: 600;
  font-size: 12px;
  height: 40px;
}

.OperatorItem .active{
  background-image: url("data:image/svg+xml,%3Csvg width='512' height='512' viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 85C0 38.0558 38.0558 0 85 0H427C473.944 0 512 38.0558 512 85V427C512 473.944 473.944 512 427 512H85C38.0558 512 0 473.944 0 427V85Z' fill='%236561A1'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M393.167 161.833C399.611 168.276 399.611 178.724 393.167 185.167L228.167 350.167C221.724 356.611 211.276 356.611 204.833 350.167L138.833 284.167C132.389 277.724 132.389 267.276 138.833 260.833C145.276 254.389 155.724 254.389 162.167 260.833L216.5 315.165L369.833 161.833C376.276 155.389 386.724 155.389 393.167 161.833Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: 24px;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}