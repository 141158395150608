.Menu {
  height: 100vh;
}
.Menu ::part(background) {
  --background: var(--dt-primary);
  background: var(--dt-primary);
}
.Menu ::part(scroll) {
  height: 100vh;
  padding: 24px 24px 0 !important;
}

.FAQModal ::part(scroll) {
  padding: 0 24px 24px !important;
}

.Menu .DTModalCloseButton {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2778 0.720824C11.7427 1.18573 11.7427 1.9395 11.2778 2.40441L7.68361 5.99856L11.2792 9.59418C11.7441 10.0591 11.7441 10.8129 11.2792 11.2778C10.8143 11.7427 10.0605 11.7427 9.59564 11.2778L6.00003 7.68215L2.40434 11.2778C1.93943 11.7427 1.18566 11.7427 0.720752 11.2778C0.255843 10.8129 0.255843 10.0592 0.720753 9.59425L4.31644 5.99856L0.722214 2.40434C0.257305 1.93943 0.257304 1.18566 0.722214 0.720752C1.18712 0.255843 1.94089 0.255843 2.4058 0.720753L6.00003 4.31498L9.59418 0.720824C10.0591 0.255914 10.8129 0.255914 11.2778 0.720824Z' fill='%23353355'/%3E%3C/svg%3E%0A");
}

.MenuTop {
  display: flex;
  flex-direction: column;
}

/* Menu User */
.MenuUser {
  display: flex;
  align-items: center;
}
.MenuUser .MenuUserImage {
  height: 42px;
  width: 42px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
}
.MenuUser .MenuUserImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.MenuUser .MenuUserName {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.SetupProfileMenuButton{
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  border-radius: 55px;
  color: var(--dt-primary);
  font-weight: 700;
  font-size: 16px;
  margin-top: 33px;
}

.MenuTopButtons {
  display: flex;
  align-items: center;
  margin: 20px 0 0 -8px;
  grid-gap: 8px;
}
.MenuTopButtons .MenuTopButton {
  position: relative;
  display: flex;
  align-items: center;
  height: unset;
  width: fit-content;
  padding: 8px 8px;
  border: none;
  border-radius: 100px;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  overflow: hidden;
}
.MenuTopButtons .MenuTopButton .MenuTopButtonBadge {
  position: absolute;
  top: 4px;
  left: 2px;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: var(--dt-pink);
}

.MenuMiddle {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  /* TODO: set margin top 30 px in the future */
  margin: 0px 0 0 -24px;
  padding: 30px 26px 0;
  border-radius: 28px 28px 0 0;
  /*
    TODO:: Hidden for live will be activated in the future
    background-color: var(--dt-charging-and-parking);
   */

}

/* Menu Rewards */
.MenuRewardsWrapper {
  padding: 30px 20px 70px;
  border-radius: 28px;
  background-color: var(--dt-pink);
}
.MenuRewards {
  display: flex;
  grid-column-gap: 12px;
}
.MenuRewardsLeft,
.MenuRewardsRight {
  display: flex;
  flex-direction: column;
}
.MenuRewardsRight {
  align-items: center;
  height: fit-content;
  margin: auto;
  text-align: center;
}
.MenuRewardsTitle {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 14px;
}
.MenuRewardsPoints {
  display: flex;
  flex-direction: column;
}
.MenuRewardsPoints .MenuRewardsTitle + div {
  display: flex;
  align-items: center;
}
.MenuRewardsPoints div span {
  margin-right: 12px;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
}
.MenuRewardsPoints {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.MenuRewardsLeft .DTButtonOutlined {
  margin-top: 38px;
}

.MenuRewardsWrapper .MenuRewards {
  flex-wrap: wrap;
}
.MenuRewardsWrapper .MenuRewardsRight {
  justify-content: flex-end;
  align-items: space-between;
  min-height: 94px;
  height: 100%;
  margin: 0 0 0 auto;
  text-align: right;
}
.MenuRewardsWrapper .MenuRewardsRight .Text {
  width: 100%;
  text-align: right;
}

.MenuRewardsChart {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100%;
  margin: auto;
  text-align: center;
}
.MenuRewardsWrapper .MenuRewardsChart {
  margin-top: 18px;
}
.MenuRewardsChart .Logo {
  position: absolute;
  top: 35px;
  left: 51%;
  transform: translateX(-50%);
}
.MenuRewardsChartDetails {
  display: flex;
  flex-direction: column;
  margin-top: -18px;
}
.MenuRewardsChartDetails .MenuRewardsChartPoint {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
}
.MenuRewardsChartDetails span {
  margin: 8px 0 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

/* Menu Bottom */
.MenuBottom {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  padding: 26px;
  margin-left: -26px;
  border-radius: 28px 28px 0 0;
  background-color: #fff;
}

/* Drifter Rewards */
.DrifterRewards ::part(scroll) {
  padding: 16px !important;
}

.CarAnimation {
  position: relative;
  max-width: 340px;
  height: 190px;
  width: 100%;
  margin: 0 auto;
  border-radius: 28px;
  background-color: #413e6c;
  overflow: hidden;
}
.CarAnimation img {
  position: absolute;
  user-select: none;
}

.CarAnimation .animationCar {
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.CarAnimation .animationTree,
.CarAnimation .animationTree2,
.CarAnimation .animationTree3 {
  bottom: 42px;
  animation: treesAnimation 12s linear infinite;
}
.CarAnimation .animationTree {
  left: 0;
}
.CarAnimation .animationTree2 {
  left: 316px;
}
.CarAnimation .animationTree3 {
  left: 606px;
}

@keyframes treesAnimation {
  from {
    transform: translateX(400px);
  }
  to {
    transform: translateX(-1100px);
  }
}

.CarAnimation .animationFloor {
  left: 0;
  bottom: 41px;
  width: 100vw;
}

.CarAnimation .animationCarStrokes,
.CarAnimation .animationCarStrokes2,
.CarAnimation .animationCarStrokes3 {
  bottom: 25px;
  z-index: 2;
  animation: strokesAnimation 6.5s linear infinite;
}
.CarAnimation .animationCarStrokes {
  left: 0;
}
.CarAnimation .animationCarStrokes2 {
  left: 600px;
}
.CarAnimation .animationCarStrokes3 {
  left: 1000px;
}

@keyframes strokesAnimation {
  from {
    transform: translateX(800px);
  }
  to {
    transform: translateX(-1300px);
  }
}

/* Settings */
.SettingsModal ::part(scroll) {
  padding: 26px !important;
}

.SettingsWrapper {
  display: flex;
  flex-direction: column;
}

.SettingRow {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 55px;
  width: 100%;
  padding-right: 30px;
  overflow: hidden;
}
.SettingRow::after {
  content: "";
  position: absolute;
  right: 4px;
  display: block;
  height: 20px;
  width: 25px;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='15' viewBox='0 0 19 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.7348 8.17829C19.0884 7.79522 19.0884 7.20479 18.7348 6.82173L12.7348 0.321725C12.3602 -0.0840959 11.7276 -0.109402 11.3217 0.265202C10.9159 0.639805 10.8906 1.27246 11.2652 1.67829L15.716 6.5L1.5 6.50001C0.947716 6.50001 0.5 6.94772 0.5 7.50001C0.5 8.05229 0.947716 8.50001 1.5 8.50001L15.716 8.5L11.2652 13.3217C10.8906 13.7275 10.9159 14.3602 11.3217 14.7348C11.7276 15.1094 12.3602 15.0841 12.7348 14.6783L18.7348 8.17829Z' fill='%23353355'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: all 0.1s ease-out;
}
.SettingRow:active::after {
  right: 0;
}
.SettingRow .SettingIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin-right: 12px;
  border-radius: 10px;
  background-color: #ececfa;
}
.SettingRow .SettingTitle {
  color: var(--dt-primary);
  font-size: 16px;
  font-weight: 600;
}

/* Change Language */
.ChangeLanguageModal ::part(scroll) {
  padding: 26px !important;
}

.RadiosWrapper {
  display: flex;
  flex-direction: column;
  grid-row-gap: 14px;
}

.RadioRow {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  width: 100%;
  padding: 0 20px;
  border: 1px solid #c0c0cb;
  border-radius: 12px;
  overflow: hidden;
}
.RadioRow .RadioRowLeft {
  display: flex;
  align-items: center;
}

.RadioRowIcon {
  margin-right: 8px;
}
.RadioRowTitle {
  color: var(--dt-primary);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

.RadioInput {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 24px;
  width: 24px;
}
.RadioRow .RadioInput input {
  pointer-events: none;
}
.RadioInput input {
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  opacity: 0;
  height: 100%;
  width: 100%;
}
.RadioInput span {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border: 1px solid #c0c0cb;
  border-radius: 50%;
  transition: all 0.1s ease-out;
}
.RadioInput span::after {
  content: "";
  opacity: 0;
  display: block;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: var(--dt-pink);
  transition: all 0.1s ease-out;
}
.RadioInput input:checked ~ span::after {
  opacity: 1;
}

/* Manage Permissions */
.ManagePermissionsModal ::part(scroll) {
  padding: 26px !important;
}

