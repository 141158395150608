.DTBadge {
    --background: var(--dt-green);
    --color: #fff;
    --padding-top: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-bottom: 0;

    position: absolute;
    top: 9px;
    left: 52%;
    display: grid;
    place-content: center;
    margin: 0;
    padding: 0 4px;
    height: 18px;
    min-width: 18px;
    border-radius: 18px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.07);
}

/* DTAccordion */
.DTAccordion {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(61, 60, 87, 0.15);
    color: var(--dt-primary);
    transition: all 0.1s ease-out;
}

.DTAccordionTitle {
    position: relative;
    font-size: 16px;
    font-weight: 600;
    min-height: 60px;
    padding: 20px 0;
    transition: all 0.1s ease-out;
}

.DTAccordionArrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(-180deg);
    display: block;
    height: 14px;
    width: 14px;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.231804 6.57961C-0.12176 6.15534 -0.0644362 5.52477 0.359841 5.17121L6.2318 0.277904C6.67682 -0.0929398 7.32323 -0.0929441 7.76825 0.277904L13.6402 5.17121C14.0645 5.52477 14.1218 6.15534 13.7682 6.57961C13.4147 7.00389 12.7841 7.06121 12.3598 6.70765L7.00003 2.24114L1.64021 6.70765C1.21593 7.06121 0.585368 7.00389 0.231804 6.57961Z' fill='%23353355'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.1s ease-out;
}

.DTAccordionContent {
    display: none;
    flex-direction: column;
    padding-bottom: 36px;
    animation: slideDown 0.15s ease-out forwards;
}

.DTAccordion.active .DTAccordionArrow {
    transform: translateY(-50%) rotate(0);
}

.DTAccordion.active .DTAccordionTitle {
    color: #b11d94;
}

.DTAccordion.active .DTAccordionContent {
    display: flex;
}


.modal-white-background ::part(scroll) {
    padding: 26px !important;
}

.modal-white-background ::part(background) {
    --background: #fcfcff;
    background: #fcfcff;
}


.DTModal {
    --background: #fff;
    --border-radius: 28px 28px 0 0;
}

.DTModal::part(content) {
    --background: #fff;
}

.DTModal ::part(scroll) {
    padding-top: 48px;
    overflow-x: hidden;
    overflow-y: auto;
}

.DTModal ::part(scroll)::-webkit-scrollbar {
    display: none;
}

.DTModal #ion-react-wrapper {
    justify-content: center;
    align-items: center;
    background: var(--dt-light);
}

.DTModalCloseButton {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    height: 32px;
    width: 32px;
    border: none;
    outline: none;
    border-radius: 50%;
    background-color: var(--dt-primary-300);
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2783 0.721313C11.7432 1.18622 11.7432 1.93999 11.2783 2.4049L7.68361 5.99954L11.2787 9.59467C11.7436 10.0596 11.7436 10.8133 11.2787 11.2783C10.8138 11.7432 10.0601 11.7432 9.59515 11.2783L6.00003 7.68313L2.40483 11.2783C1.93992 11.7432 1.18615 11.7432 0.721241 11.2783C0.256331 10.8134 0.256332 10.0596 0.721241 9.59474L4.31644 5.99954L0.721726 2.40483C0.256816 1.93992 0.256816 1.18615 0.721726 0.721241C1.18664 0.256331 1.9404 0.256332 2.40531 0.721241L6.00003 4.31595L9.59467 0.721313C10.0596 0.256403 10.8133 0.256403 11.2783 0.721313Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 12px;
    transition: all 0.1s ease-out;
}
.DTMenuModalCloseButton {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    height: 32px;
    width: 32px;
    border: none;
    outline: none;
    transition: all 0.1s ease-out;
}

.CloseDTModalButton:active {
    background-color: #9797ae;
}


/* DTAlert */
.DTAlert {
    position: fixed;
    bottom: -2rem;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    padding: 16px 18px;
    min-height: 54px;
    max-width: 344px;
    width: calc(100vw - 32px);
    border: 1px solid #efbf14;
    border-radius: 12px;
    background-color: #fffaef;
    z-index: 40000004;
    transition: all 0.15s ease-out;
}

.DTAlert:active {
    transform: translateX(-50%) scale(0.98);
}

.DTAlert.open {
    display: flex;
    bottom: 1rem;
    opacity: 1;
}

.DTAlertIcon {
    display: block;
    flex-shrink: 0;
    height: 21px;
    width: 21px;
    margin: 0 12px 0 0;
    border-radius: 50%;
    background-color: #efbf14;
    background-image: url("data:image/svg+xml,%3Csvg width='110' height='513' viewBox='0 0 110 513' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M102.673 0.90918L94.5104 358.567H14.8659L6.95096 0.90918H102.673ZM54.6882 512.909C39.6827 512.909 26.8209 507.633 16.1027 497.079C5.54936 486.526 0.272705 473.664 0.272705 458.494C0.272705 443.653 5.54936 430.956 16.1027 420.403C26.8209 409.85 39.6827 404.573 54.6882 404.573C69.3638 404.573 82.0608 409.85 92.779 420.403C103.662 430.956 109.104 443.653 109.104 458.494C109.104 468.552 106.548 477.704 101.436 485.949C96.4891 494.194 89.8933 500.789 81.6486 505.736C73.5687 510.518 64.5819 512.909 54.6882 512.909Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 6px 12px;
}

.DTAlert div {
    margin-right: 8px;
    color: var(--dt-primary);
    font-size: 12px;
    font-weight: 500;
}

.DTAlert button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: fit-content;
    text-align: center;
    margin: 0 0 0 auto;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    color: #efbf14;
    font-size: 12px;
    font-weight: 500;
}

.DTAlert.-success {
    border-color: #38bc8e;
    background-color: #eefff9;
}

.DTAlert.-success button {
    color: #38bc8e;
}

.DTAlert.-success .DTAlertIcon {
    background-color: #38bc8e;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.15115 1.07893C8.49406 1.43876 8.48033 2.00844 8.12049 2.35135L4.38411 5.9119C4.0133 6.26526 3.43037 6.26527 3.05956 5.9119L0.87873 3.8337C0.518893 3.4908 0.505166 2.92111 0.848069 2.56128C1.19097 2.20144 1.76066 2.18771 2.12049 2.53062L3.72183 4.0566L6.87873 1.04826C7.23857 0.705361 7.80825 0.719089 8.15115 1.07893Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: 10px;
}

.DTAlert.-error {
    border-color: #e03232;
    background-color: #ffefef;
}

.DTAlert.-error button {
    color: #e03232;
}

.DTAlert.-error .DTAlertIcon {
    background-color: #e03232;
    background-image: url("data:image/svg+xml,%3Csvg width='513' height='512' viewBox='0 0 513 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M492.825 19.1747C518.383 44.7328 518.383 86.1708 492.825 111.729L348.565 255.989L492.847 400.271C518.406 425.829 518.406 467.267 492.847 492.825C467.289 518.383 425.851 518.383 400.293 492.825L256.011 348.543L111.723 492.831C86.1647 518.39 44.7268 518.39 19.1686 492.831C-6.38953 467.273 -6.38955 425.835 19.1686 400.277L163.457 255.989L19.1907 111.723C-6.36748 86.1647 -6.36746 44.7268 19.1907 19.1686C44.7488 -6.38953 86.1867 -6.38955 111.745 19.1686L256.011 163.435L400.271 19.1747C425.829 -6.38346 467.267 -6.38346 492.825 19.1747Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: 10px;
}

/* DTAlertModal */
.DTAlertModal {
    z-index: 24000 !important;
}

.DTAlertModal::part(content) {
    --background: var(--dt-page-background);
    --border-radius: 18px;

    height: 300px;
    width: 90vw;
    box-sizing: border-box;
}

.DTAlertModal::part(scroll) {
    padding: 16px;
}

.DTAlertModal::part(scroll)::-webkit-scrollbar {
    display: none;
}

.DTAlertModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 20px;
}

.DTAlertModal .Text {
    display: flex;
    justify-content: center;
    text-align: center;
}

/* Input */

.DTInputWrapper{
    position: relative;
    flex-shrink: 0;
    width: 100%;
    --background: white;
    --color	: var(--dt-primary);
    --padding-start: 0;
    --border-width: 0px;
}

.DTInputWrapper .DTInputBorder{
    border: 1px solid var(--dt-primary-300);
    width: 100%;
    border-radius: 12px;
    height: 55px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DTInputWrapper IonInput{
    --color	: var(--dt-primary);
    border: none;
}

.DTInputBorder.focus{
    border-color: var(--dt-primary);
}

.DTCountryInput.-static input {
    border-radius: 12px;
}
.DTCountryInput.-static label {
    opacity: 0.6;
}

.DTCountryInput.-static input:focus ~ label,
.DTCountryInput.-static input:not(:placeholder-shown) ~ label {
    opacity: 0;
    color: #635c5c;
    font-size: 16px;
    font-weight: 400;
}

.DTInputWrapper.error .DTInputBorder {
    border-color: var(--dt-error);
}

.DTInputWrapper.success .DTInputBorder {
    border-color: var(--dt-success);
}

.DTInputWarnText{
    position: relative;
    right: 10px;
    top: -60px;
    transform: translateY(-100%);
    display: block;
    color: #9797ae;
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    transition: all 0.1s ease-out;
    z-index: 99999999;
}

.DTInputWrapper.error+span.DTInputWarnText {
    color: var(--dt-error);
}

.DTInputWrapper.success+span.DTInputWarnText {
    color: var(--dt-success);
}


/* DTToast */
.ToastWrapper {
    position: relative;
    height: 85px;
    width: 100%;
    border-radius: 14px;
    background-color: #fff;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.1);
    z-index: 201;
    transform: translateY(-200vw);
    /*TODO: call from variables*/
    margin-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
    margin-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
    margin-bottom: constant(
            safe-area-inset-bottom
    ); /* Status bar height on iOS 11.0 */
    margin-bottom: env(safe-area-inset-bottom); /* Status bar height on iOS 11+ */
    display: none;
}

.ToastWrapper.active {
    display: block;
    transition: all 0.4s ease-out;
    animation: toastSlideDown 0.15s ease-out forwards;
    animation-delay: 1.2s;
}

.ToastContent {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    margin-right: 12px;
    padding: 0 20px;
}

.ToastContent > div {
    display: flex;
    flex-direction: column;
    grid-row-gap: 4px;
}

.ToastTitle {
    color: var(--dt-primary);
    font-size: 14px;
    font-weight: 600;
}

.ToastMessage {
    color: var(--dt-pink);
    font-size: 14px;
    font-weight: 500;
}

.ToastIcon {
    flex-shrink: 0;
    height: 34px;
    width: 34px;
    margin-right: 12px;
    border-radius: 8px;
    background-color: var(--dt-pink);
    background-image: url("data:image/svg+xml,%3Csvg width='197' height='512' viewBox='0 0 197 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 512L61.515 151.747H179.018L117.503 512H0ZM128.557 109.53C111.896 109.53 98.3599 104.136 87.9472 93.3467C77.5345 82.5579 73.4495 69.6582 75.6923 54.6477C77.935 39.4808 85.7846 26.5811 99.241 15.9487C112.697 5.31623 127.676 0 144.176 0C160.836 0 174.212 5.31623 184.305 15.9487C194.557 26.5811 198.562 39.4808 196.319 54.6477C194.397 69.6582 186.708 82.5579 173.251 93.3467C159.955 104.136 145.057 109.53 128.557 109.53Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6px;
}

.ToastButton {
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin-left: auto;
    border-radius: 4px;
    background-image: url("data:image/svg+xml,%3Csvg width='513' height='512' viewBox='0 0 513 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M496.134 15.8658C517.284 37.016 517.284 71.3073 496.134 92.4575L332.603 255.989L496.156 419.543C517.307 440.693 517.307 474.984 496.156 496.134C475.006 517.284 440.715 517.284 419.565 496.134L256.011 332.581L92.4543 496.137C71.3042 517.288 37.0128 517.288 15.8626 496.137C-5.28756 474.987 -5.28753 440.696 15.8627 419.546L179.419 255.989L15.8847 92.4543C-5.26551 71.3042 -5.26554 37.0128 15.8847 15.8626C37.0349 -5.28756 71.3262 -5.28753 92.4764 15.8627L256.011 179.397L419.543 15.8658C440.693 -5.28443 474.984 -5.28443 496.134 15.8658Z' fill='%23353355'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    transition: all 0.15s ease-out;
}

.ToastButton:active {
    background-color: #f7f7f7;
}

.ToastWrapper.error {
    background-color: #ec4848;
    color: #fff;
}

.ToastWrapper.searchThisArea.second{
    margin-top: 24%;
}

.ToastWrapper.searchThisArea .ToastIcon {
    background-image: url("../icons/search_this_area_icon.svg");
    background-color: #fff;
    background-size: 30px;
}

.ToastWrapper.error .ToastIcon {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.56066 12.5607C1.97487 13.1464 1.02513 13.1464 0.43934 12.5607C-0.146447 11.9749 -0.146447 11.0251 0.43934 10.4393L4.37868 6.5L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146447 1.97487 -0.146447 2.56066 0.43934L6.5 4.37868L10.4393 0.43934C11.0251 -0.146447 11.9749 -0.146447 12.5607 0.43934C13.1464 1.02513 13.1464 1.97487 12.5607 2.56066L8.62132 6.5L12.5607 10.4393C13.1464 11.0251 13.1464 11.9749 12.5607 12.5607C11.9749 13.1464 11.0251 13.1464 10.4393 12.5607L6.5 8.62132L2.56066 12.5607Z' fill='%23EB001B'/%3E%3C/svg%3E%0A");
    background-color: #fff;
    background-size: 10px;
}

.ToastWrapper.error .ToastTitle {
    font-weight: 400 !important;
    color: #fff !important;
}

.ToastWrapper.error .ToastMessage {
    color: #fff !important;
}

.ToastWrapper.success {
    background-color: var(--dt-success);
    color: #fff;
}

.ToastWrapper.success .ToastIcon {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.0607 0.93934C15.6464 1.52513 15.6464 2.47487 15.0607 3.06066L7.13137 10.9899C6.50654 11.6148 5.49347 11.6148 4.86863 10.99L0.93934 7.06066C0.353553 6.47487 0.353553 5.52513 0.93934 4.93934C1.52513 4.35355 2.47487 4.35355 3.06066 4.93934L6 7.87868L12.9393 0.93934C13.5251 0.353553 14.4749 0.353553 15.0607 0.93934Z' fill='%2334D298'/%3E%3C/svg%3E%0A");
    background-color: #fff;
    background-size: 14px;
}

.ToastWrapper.success .ToastTitle {
    color: #fff !important;
}

.ToastWrapper.success .ToastMessage {
    color: #fff !important;
}

/* DTFab */
.DTFab {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 50%;
    outline: none;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    color: var(--dt-primary);
    overflow: hidden;
}

.DTFab.-arrow-right {
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.7348 6.82172C8.0884 7.20478 8.0884 7.79522 7.7348 8.17828L1.7348 14.6783C1.3602 15.0841 0.72754 15.1094 0.321719 14.7348C-0.0841014 14.3602 -0.109408 13.7275 0.265196 13.3217L5.63909 7.5L0.265197 1.67828C-0.109407 1.27246 -0.0841008 0.639797 0.32172 0.265194C0.727541 -0.10941 1.3602 -0.0841038 1.7348 0.321717L7.7348 6.82172Z' fill='%23353355'/%3E%3C/svg%3E%0A");
    background-size: 8px;
    background-position: 22px center;
}

/* DTLink */
.DTLink {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    color: #83819e;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    overflow: hidden;
}

/* DTButton */
.DTButton {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 100%;
    padding: 0 12px;
    border: none;
    border-radius: 55px;
    outline: none;
    background-color: var(--dt-primary);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    overflow: hidden;
}

.DTButton.-fit {
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
}

.DTButtonOutlined {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    width: 100%;
    padding: 0 30px;
    border-radius: 46px;
    border: 1px solid var(--dt-primary);
    outline: none;
    background-color: transparent;
    color: var(--dt-primary);
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
}

.DTButtonOutlinedDark {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    width: fit-content;
    padding: 0 30px;
    border-radius: 46px;
    border: 1px solid rgba(61, 60, 87, 0.15);
    outline: none;
    background-color: transparent;
    color: var(--dt-primary);
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
}

.DTButtonPink {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 100%;
    padding: 0 12px;
    border: none;
    border-radius: 55px;
    outline: none;
    background-color: var(--dt-pink);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    overflow: hidden;
}

.DTButtonPink.-fit {
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
}


/* DTItem */
.DTItems {
    display: flex;
    flex-direction: column;
}
.DTItem {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    margin: 12px 0;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='15' viewBox='0 0 19 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.7348 8.17829C19.0884 7.79522 19.0884 7.20479 18.7348 6.82173L12.7348 0.321725C12.3602 -0.0840959 11.7276 -0.109402 11.3217 0.265202C10.9159 0.639805 10.8906 1.27246 11.2652 1.67829L15.716 6.5L1.5 6.50001C0.947716 6.50001 0.5 6.94772 0.5 7.50001C0.5 8.05229 0.947716 8.50001 1.5 8.50001L15.716 8.5L11.2652 13.3217C10.8906 13.7275 10.9159 14.3602 11.3217 14.7348C11.7276 15.1094 12.3602 15.0841 12.7348 14.6783L18.7348 8.17829Z' fill='%23353355'/%3E%3C/svg%3E%0A");
    background-position: calc(100% - 2px) center;
    background-repeat: no-repeat;
    color: var(--dt-primary);
    font-size: 20px;
    font-weight: 500;
    overflow: hidden;
    transition: all 0.1s ease-out;
}

.DTItem:active {
    background-position: 100% center;
}

.DTItem.-danger {
    color: #e03232;
    background-image: none;
}

.DTItem .DTItemBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 28px;
    height: 28px;
    padding: 0 6px;
    margin-left: 8px;
    border-radius: 14px;
    background-color: #ececfa;
    color: var(--dt-primary);
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
}

.ProfileBottom .DTItem {
    font-size: 14px;
    font-weight: 600;
}

/* DTItemSliding */
.InboxModal ::part(scroll) {
    padding: 0;
}

.DTItemSliding {
    overflow: visible;
    transition: all 0.1s ease-out;
}

.DTItemSliding.notRead {
    background: #f2f2fb;
}

.NotReadIcon {
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background: #b11d94;
    position: absolute;
    right: 36px;
}

.DTItemSliding:not(:first-of-type) {
    border-top: 1px solid rgba(61, 60, 87, 0.15);
}

.DTItemSliding .item::part(native) {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --border-width: 0;
    --background: transparent;

    --inner-border-width: 0;
    --inner-padding-top: 22px;
    --inner-padding-bottom: 22px;
    --inner-padding-start: 62px;
    --inner-padding-end: 62px;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.15s ease-out;
}

.DTItemSliding .item-inner {
    overflow: visible;
}

.DTItemSliding .item .DTItemSlidingIcon {
    position: absolute;
    top: 30px;
    left: 15px;
    display: block;
    height: 34px;
    width: 34px;
    border-radius: 8px;
    background-color: var(--dt-pink);
    background-image: url("data:image/svg+xml,%3Csvg width='197' height='512' viewBox='0 0 197 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 512L61.515 151.747H179.018L117.503 512H0ZM128.557 109.53C111.896 109.53 98.3599 104.136 87.9472 93.3467C77.5345 82.5579 73.4495 69.6582 75.6923 54.6477C77.935 39.4808 85.7846 26.5811 99.241 15.9487C112.697 5.31623 127.676 0 144.176 0C160.836 0 174.212 5.31623 184.305 15.9487C194.557 26.5811 198.562 39.4808 196.319 54.6477C194.397 69.6582 186.708 82.5579 173.251 93.3467C159.955 104.136 145.057 109.53 128.557 109.53Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6px;

}

.DTItemSliding .item .DTItemSlidingIcon.-reward {
    display: block;
    background-color: #efbf14;
    background-image: url("data:image/svg+xml,%3Csvg width='512' height='512' viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M460.8 256V486.4C460.8 500.539 449.339 512 435.2 512H76.8C62.6615 512 51.2 500.539 51.2 486.4V256H25.6C11.4615 256 0 244.539 0 230.4V153.6C0 139.462 11.4615 128 25.6 128H486.4C500.539 128 512 139.462 512 153.6V230.4C512 244.539 500.539 256 486.4 256H460.8Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M153.6 0H358.4C372.539 0 384 11.4615 384 25.6V59.7333C384 73.8718 372.539 85.3333 358.4 85.3333H153.6C139.462 85.3333 128 73.8718 128 59.7333V25.6C128 11.4615 139.462 0 153.6 0Z' fill='white'/%3E%3C/svg%3E%0A");
}

.DTItemSliding .item .DTItemSlidingTitle {
    color: var(--dt-primary);
    font-size: 16px;
    font-weight: 500;
}

.DTItemSliding .item .DTItemSlidingDesc {
    color: var(--dt-primary);
    font-size: 14px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
}

.DTItemSliding .item .DTItemSlidingDate {
    color: rgba(53, 51, 85, 0.65);
    font-size: 14px;
    font-weight: 400;
}

.DTItemSlidingOption {
    --background: #e03232;
}

.DTItemSlidingOptionIcon {
    --background: #e03232;

    display: block;
    height: 24px;
    width: 24px;
    background: var(--background);
    background-image: url("data:image/svg+xml,%3Csvg width='461' height='512' viewBox='0 0 461 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M181.09 245.828C169.112 245.828 159.95 254.657 159.95 266.202V390.465C159.95 402.009 169.111 410.839 181.09 410.839C193.069 410.839 202.23 402.01 202.23 390.465V266.202C202.23 254.658 193.069 245.828 181.09 245.828Z' fill='white'/%3E%3Cpath d='M279.69 245.828C267.712 245.828 258.55 254.657 258.55 266.202V390.465C258.55 402.009 267.711 410.839 279.69 410.839C291.669 410.839 300.83 402.01 300.83 390.465V266.202C300.83 254.658 291.669 245.828 279.69 245.828Z' fill='white'/%3E%3Cpath d='M52.1505 200.321V457.676C52.1505 487.556 77.5138 512 108.518 512H352.299C383.303 512 408.666 487.556 408.666 457.676L408.662 200.321C438.254 197.605 460.8 174.516 460.8 145.996V118.837C460.8 88.957 435.437 64.5131 404.432 64.5131L324.112 64.5085V54.3242C324.112 24.4438 298.749 0 267.745 0H193.06C162.056 0 136.693 24.4438 136.693 54.3242V64.5085H56.3676C25.3633 64.5085 0 88.9523 0 118.833V145.992C0.00481618 174.516 22.558 197.601 52.1505 200.321ZM352.305 471.258H108.524C100.775 471.258 94.4309 465.145 94.4309 457.676V200.321H366.398V457.676C366.398 465.145 360.06 471.258 352.305 471.258ZM178.979 54.3233C178.979 46.8544 185.322 40.7407 193.073 40.7407H267.757C275.507 40.7407 281.85 46.854 281.85 54.3233V64.5075H178.979V54.3233ZM42.2864 118.831C42.2864 111.363 48.6297 105.249 56.3798 105.249H404.449C412.199 105.249 418.543 111.362 418.543 118.831V145.991C418.543 153.459 412.2 159.573 404.449 159.573H56.3798C48.63 159.573 42.2864 153.46 42.2864 145.991V118.831Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
}

.DTItemSliding.item-sliding-active-slide {
    background-color: #f2f2fb;
}

.DTItemSliding.item-sliding-active-swipe-end .item::part(native) {
    opacity: 0.4;
}

/* DTList */
.DTList {
    padding: 0 0 0 14px;
    list-style-type: none;
}

.DTList li {
    position: relative;
    color: var(--dt-primary);
    font-size: 14px;
    font-weight: 400;
}

.DTList li:not(:first-of-type) {
    margin: 16px 0 0;
}

.DTList li::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -14px;
    transform: translateY(-50%);
    display: block;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: var(--dt-pink);
}
