* {
  font-family: var(--dt-font-family);
}

.title,
.title * {
  font-size: 26px;
  font-weight: 600;
  line-height: 29px;
}

.title-2,
.title-2 * {
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
}

.subtitle,
.subtitle * {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}

.subtitle-2,
.subtitle-2 * {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}

.body,
.body * {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.body-2,
.body-2 * {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}

.caption,
.caption * {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
}

.link {
  cursor: pointer;
  color: var(--dt-pink);
}
.link.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.Text .link {
  font: inherit;
}
