#about-drifter{
    color: var(--dt-primary);
    .e-mail{
        color: var(--dt-pink)
    }
    .line{
        display: inline-flex;
        width: 100%;
        border-bottom: 1px solid var(--dt-primary);
    }
}