.SelectAmount {
  margin-top: 30px;
  .AmountItem {
    width: 80px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C0C0CB;
    border-radius: 12px;
    color: var(--dt-primary);
    background-color: white;

    &.-active {
      border: 1px solid #B11D94;
      background-color: #B11D94;
      color: white;
    }
  }
}

.SelectCard {
  padding-left: 20px;
  .DrifterCardPaymentMethodsRadio {
    position: relative;
    display: flex;
    padding: 17px;
    border: 1px solid var(--dt-primary-300);
    border-radius: 12px;
    transition: all 0.15s ease-out;
    background-color: white;
    width: 201px;
    height: 88px;
  }

  .DrifterCardPaymentMethodsRadio::after {
    content: "";
    position: absolute;
    top: 11px;
    right: 11px;
    display: block;
    height: 16px;
    width: 16px;
    border: 1px solid var(--dt-primary-300);
    border-radius: 50%;
    background-color: #fff;
    background-position: -40px center;
    background-repeat: no-repeat;
    background-size: 11px;
    transition: all 0.1s ease-out;
  }

  .DrifterCardPaymentMethodsRadioWrapper {
    
  }

  .DrifterCardPaymentMethodsRadioWrapper input[type="radio"] {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    z-index: 2;
  }

  .DrifterCardPaymentMethodsRadioWrapper input[type="radio"]:checked+.DrifterCardPaymentMethodsRadio {
    border-color: var(--dt-pink);
  }

  .DrifterCardPaymentMethodsRadioWrapper input[type="radio"]:checked+.DrifterCardPaymentMethodsRadio::after {
    border-radius: 50%;
    background-color: var(--dt-pink);
    background-position: center;
  }

  .DrifterCardPaymentMethodsRadioContent {
    display: flex;
    flex-direction: column;
  }
  
  .AddNewCardSlide{
    border: 1px solid var(--dt-primary-300);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 71px;
    height: 88px;
    cursor: pointer;
  }

  .addPayment{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 268px;
    gap: 8px;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid rgba(61, 60, 87, 0.15);
    border-radius: 55px;
    margin-top: 17px;
  }

  .LastSlide{
    margin: 0px;
    width: 71px;
  }

}
.DrifterCardAdvantages{
  padding-left: 20px;
  margin-bottom: 100px;
  .DrifterCardAdvantagesSlider{
    margin-top: 25px;
  }
  .DrifterCardAdvantagesItem{
    width: 205px;
    border-radius: 16px;
    padding: 24px;
    .AdvantageIconWrapper{
      width: 100px;
      height: 100px;
      border-radius: 0 16px 0 0 !important;
      clip-path: circle(50.0% at 100% 0);
      position: relative;
      top: -23px;
      right: -72px;
      .AdvantageIcon{
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }
    #label {
      margin-top: -100px;
      .AdvantageLabel{
        color: var(--dt-primary);
        .AdvantageLabelFirstSpan{
          font-size: 29px;
          font-weight: 700;
          line-height: 40px;
        }
        .AdvantageLabelSecondSpan{
          font-weight: 600;
          font-size: 16px;
          line-height: 22.4px;
        }
        .AdvantageLabelThirdSpan{
          font-weight: 400;
          font-size: 16px;
          line-height: 22.4px;
        }
      }
    }
  }
}