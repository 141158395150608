
/* ANIMATIONS */
.chargingWaveAnimation {
    animation: chargingWaveAnimation 3s ease-out infinite;
}

.chargingWaveAnimationInner {
    animation: chargingWaveAnimationInner 2.8s ease-out infinite;
}

#splashLogo {
    clip-path: inset(100% 0 0 0);
    animation: splashLogoAnimation 1s ease-out forwards;
}

.shake {
    animation: shakeAnimation 200ms 2 linear;
}

.spin {
    animation: spinAnimation 1s linear infinite;
}

.fade-in {
    animation: fadeIn 0.1s ease-out forwards;
}



@keyframes PageSlideIn {
    0% {
        opacity: 0;
        transform: translate3d(0, 5vh, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes ChargingOverlayWave {
    0%,
    100% {
        clip-path: polygon(
                0% 0%,
                6% 2%,
                12% 4%,
                18% 6%,
                24% 8%,
                30% 10%,
                36% 12%,
                42% 12%,
                48% 10%,
                54% 8%,
                60% 6%,
                66% 4%,
                72% 2%,
                78% 2%,
                84% 4%,
                90% 6%,
                96% 8%,
                102% 10%,
                100% 0%,
                100% 100%,
                0% 100%
        );
    }
    25% {
        clip-path: polygon(
                0% 2%,
                6% 4%,
                12% 6%,
                18% 8%,
                24% 10%,
                30% 12%,
                36% 12%,
                42% 10%,
                48% 8%,
                54% 6%,
                60% 4%,
                66% 2%,
                72% 2%,
                78% 4%,
                84% 6%,
                90% 8%,
                96% 10%,
                102% 12%,
                100% 0%,
                100% 100%,
                0% 100%
        );
    }
    50% {
        clip-path: polygon(
                0% 4%,
                6% 6%,
                12% 8%,
                18% 10%,
                24% 12%,
                30% 12%,
                36% 10%,
                42% 8%,
                48% 6%,
                54% 4%,
                60% 2%,
                66% 2%,
                72% 4%,
                78% 6%,
                84% 8%,
                90% 10%,
                96% 12%,
                102% 12%,
                100% 0%,
                100% 100%,
                0% 100%
        );
    }
    75% {
        clip-path: polygon(
                0% 6%,
                6% 8%,
                12% 10%,
                18% 12%,
                24% 12%,
                30% 10%,
                36% 8%,
                42% 6%,
                48% 4%,
                54% 2%,
                60% 2%,
                66% 4%,
                72% 6%,
                78% 8%,
                84% 10%,
                90% 12%,
                96% 12%,
                102% 10%,
                100% 0%,
                100% 100%,
                0% 100%
        );
    }
}

@keyframes drifterCarAnimation {
    0% {
        transform: translateY(100px) rotate3d(0, 1, 0, 0deg);
    }
    50% {
        transform: translateY(-10px) rotate3d(0, 1, 0, 0deg);
    }
    100% {
        transform: translateY(0) rotate3d(0, 1, 0, 0deg);
    }
}

@keyframes cardSlalom {
    25% {
        transform: rotate3d(0, 1, 0.05, 15deg) translateY(0);
    }
    50% {
        transform: rotate3d(0, 1, 0, 0deg) translateY(0);
    }
    75% {
        transform: rotate3d(0.05, 1, 0, -15deg) translateY(0);
    }
}

@keyframes discountIcon1Animation {
    0% {
        transform: translateY(170px) rotateY(0);
        opacity: 0;
    }
    10% {
        transform: translateY(170px) rotateY(0);
        opacity: 0;
    }
    100% {
        transform: translateY(70px) rotateY(180deg);
    }
}

@keyframes coinRotateFirst {
    from {
        transform: rotateY(0deg);
    }
    40% {
        transform: rotateY(360deg);
    }
    to {
        transform: rotateY(360deg);
    }
}

@keyframes discountIcon2Animation {
    0% {
        transform: translate(-100px, 5px) rotateY(0);
        opacity: 0;
    }
    50% {
        transform: translate(-100px, 5px) rotateY(0);
        opacity: 0;
    }

    80% {
        transform: translate(-100px, -80px) rotateY(0);
        opacity: 1;
    }

    100% {
        transform: translate(-100px, -60px) rotateY(180deg);
        opacity: 1;
    }
}

@keyframes coinRotateSecond {
    from {
        transform: translate(-100px, -60px) rotateY(0deg);
    }
    40% {
        transform: translate(-100px, -60px) rotateY(360deg);
    }
    to {
        transform: translate(-100px, -60px) rotateY(360deg);
    }
}

@keyframes discountIcon3Animation {
    0% {
        transform: translate(100px, -40px) rotateY(0);
        opacity: 0;
    }
    50% {
        transform: translate(100px, -40px) rotateY(0);
        opacity: 0;
    }

    60% {
        transform: translate(100px, -180px) rotateY(0);
        opacity: 1;
    }

    100% {
        transform: translate(100px, -150px) rotateY(180deg);
        opacity: 1;
    }
}

@keyframes coinRotateThird {
    from {
        transform: translate(100px, -150px) rotateY(0deg);
    }
    50% {
        transform: translate(100px, -150px) rotateY(360deg);
    }
    to {
        transform: translate(100px, -150px) rotateY(360deg);
    }
}

@keyframes LeftTopAnim {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes RightTopAnim {
    0% {
        transform: translate(100%, -100%);
    }
    100% {
        transform: translate(50%, -40%);
    }
}

@keyframes LeftBottomAnim {
    0% {
        transform: translate(-100%, 100%);
    }
    100% {
        transform: translate(-50%, 60%);
    }
}

@keyframes LastSlideCarAnim {
    0% {
        top: -14%;
        left: 300px;
        transform: rotate(90deg);
    }
    40% {
        top: -14%;
        left: -20px;
        transform: rotate(90deg);
    }
    50% {
        top: -10%;
        left: -20px;
        transform: rotate(0);
    }
    100% {
        top: 75%;
        left: -20px;
        transform: rotate(0);
    }
}

@keyframes CarShadowFirst {
    0% {
        transform: scale(0);
        background-color: rgba(179, 29, 148, 0);
    }
    60% {
        transform: scale(1);
        background-color: rgba(179, 29, 148, 0.4);
    }
    80% {
        transform: scale(1);
        background-color: rgba(179, 29, 148, 0.4);
    }
    100% {
        transform: scale(1);
        background-color: rgba(179, 29, 148, 0);
    }
}

@keyframes CarShadowSecond {
    0% {
        transform: scale(0);
        background-color: rgba(179, 29, 148, 0);
    }
    60% {
        transform: scale(1);
        background-color: rgba(179, 29, 148, 0.35);
    }
    80% {
        transform: scale(1);
        background-color: rgba(179, 29, 148, 0.35);
    }
    100% {
        transform: scale(1);
        background-color: rgba(179, 29, 148, 0);
    }
}

@keyframes carTail {
    0% {
        width: 0;
        height: 0;
        left: 100%;
    }
    40% {
        width: 100%;
        top: -6px;
        left: -7px;
    }
    50% {
        height: 0;
    }
    100% {
        width: 100%;
        height: 100%;
        top: -6px;
        left: -7px;
    }
}

@keyframes hiddenButtonAnimation {
    from {
        opacity: 0;
        transform: translateY(100vh);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes onBoardingFirstHalfCircleAnimation {
    from {
        transform: scale(1.2);
    }
    30% {
        transform: scale(1);
    }
}

@keyframes onBoardingSecondtHalfCircleAnimation {
    from {
        transform: scale(1.2);
    }
    30% {
        transform: scale(1);
    }
}

@keyframes Building1Animation {
    from {
        left: 100%;
    }
    to {
        left: -20%;
    }
}

@keyframes Building2Animation {
    from {
        left: 120%;
    }
    to {
        left: -20%;
    }
}

@keyframes Building3Animation {
    from {
        left: 140%;
    }
    to {
        left: -20%;
    }
}

@keyframes Building5Animation {
    from {
        left: 160%;
    }
    to {
        left: -20%;
    }
}

@keyframes tree1Animation {
    from {
        left: 100%;
    }
    to {
        left: -15%;
    }
}

@keyframes tree2Animation {
    from {
        left: 200%;
    }
    to {
        left: -15%;
    }
}

@keyframes tree3Animation {
    from {
        left: 300%;
    }
    to {
        left: -15%;
    }
}

@keyframes wheelsAnim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes onboardingParkLightsAnimation {
    0% {
        opacity: 1;
        transform: rotate(-90deg) translate(-277px, 0px);
    }
    90% {
        opacity: 1;
        transform: rotate(-90deg) translate(-87px, 0px);
    }

    100% {
        opacity: 0;
        transform: rotate(-90deg) translate(-87px, 0px);
    }
}

@keyframes onboardingParkChargeIconAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes onboardingParkChargeIconAnimation2 {
    from {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    to {
        transform: scale(1);
    }
}

@keyframes onboardingParkCarAnimation {
    from {
        opacity: 0.8;
        transform: rotate(0deg) translate(8px, 160px);
    }
    to {
        opacity: 1;
        transform: rotate(0deg) translate(8px, -30px);
    }
}

@keyframes onboardingChargingWaveAnimation {
    10% {
        width: 525px;
        height: 525px;
        border: 100px solid #34d298;
    }
    25% {
        width: 525px;
        height: 525px;
        transform: scale(1);
    }
    40% {
        width: 525px;
        height: 525px;
        opacity: 0.4;
        border: 0;
        transform: scale(1);
    }
    100% {
        border: 0;
    }
}

@keyframes onboardingChargingWaveInnerAnimation {
    15% {
        width: 250px;
        height: 250px;
        border: 75px solid #34d298;
        transform: scale(1);
    }
    40% {
        width: 250px;
        height: 250px;
        opacity: 0.5;
        border: 0;
        transform: scale(1);
    }
    100% {
        border: 0;
    }
}

@keyframes onboardingSubscribeRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes chargingWaveAnimation {
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(1.8);
    }
}

@keyframes chargingWaveAnimationInner {
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(1.8);
    }
}

@keyframes splashLogoAnimation {
    0% {
        clip-path: inset(100% 0 0 0);
    }
    100% {
        clip-path: inset(0% 0 0 0);
    }
}

@keyframes waves {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) scale(8);
        opacity: 0;
    }
}

@keyframes shakeAnimation {
    0% {
        transform: translate(3px, 0);
    }
    50% {
        transform: translate(-3px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes spinAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes chargingSpin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes toastSlideDown {
    from {
        opacity: 0;
        transform: translateY(-200vw);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes toolbarSlideUp {
    from {
        opacity: 0;
        transform: translateY(200%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes toolbarSlideDown {
    from {
        opacity: 0;
        transform: translateY(-200%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes toolbarSlideLeft {
    from {
        opacity: 0;
        transform: translateX(200%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes shine-loading-image {
    0% {
        background-position: -32px;
    }
    40%,
    100% {
        background-position: 208px;
    }
}

@-webkit-keyframes arrow-move {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes arrow-move {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes arrow-move {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes arrow-move {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

