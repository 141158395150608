body .Map {
  position: absolute;
  inset: 0;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  transition: transform 0.8s ease-out;
}
body .Map::-webkit-scrollbar {
  height: 0;
  width: 0;
}

body .Map *,
body .Map [aria-label="Map"],
body .Map [aria-label="Map"]:focus-visible {
  outline: none !important;
}

.Map.zoomed-in {
  transform: scale(1.2);
}

.Map .icon,
.Map svg {
  transform: translate(-50%, -100%);
}

.Map .centerMarker {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 20000000;
}
.Map .centerMarker::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 100px;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle opacity='0.11' cx='50' cy='50' r='50' fill='%23353355'/%3E%3Ccircle opacity='0.22' cx='50' cy='50' r='27' fill='%23353355'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
  z-index: -1;
}

.Map .centerMarker::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 100px;
  background-color: #c01b58;
  opacity: 1;
  border-radius: 50%;
  transform: scale(0) translate(-50%, -50%);
  animation: centerMarkerPulseAnimation 2s cubic-bezier(0.41, 0.01, 0.36, 1) infinite;
  transform-origin: top left;
  z-index: -1;
}

@keyframes centerMarkerPulseAnimation {
  from {
    opacity: .5;
    transform: scale(0) translate(-50%, -50%);
  }
  to {
    opacity: 0;
    transform: scale(1) translate(-50%, -50%);
  }
}

/* Toolbar */
.SearchDropItem {
  cursor: pointer;
  user-select: none;
  padding: 8px 6px;
  color: var(--dt-primary);
  font-size: 14px;
  font-weight: 700;
  transition: all 0.1s ease-out;
}
.SearchDropItem:active {
  transform: scale(0.98);
}
.SearchDropItem:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* Station */
.StationIcon,
.StationIcon img,
.StationIcon svg path {
  position: absolute;
  transition: all 0.1s ease-out;
  z-index: 20000000;
}

/* Station Detail Modal */
.StationDetailModal {
  color: #fff;
  transition: all 0.1s ease-out;
  height: 100%;
}
.StationDetailModal.radiusModal {
  --border-radius: 35px 35px 0 0;
}
.StationDetailModal::part(content) {
  color: #fff;
}
.StationDetailModal>#ion-react-wrapper{
  --background: var(--dt-primary);
  background: var(--dt-primary);
}

.StationDetailModal .StationContentParkingWrapper {
  padding-bottom: 80px;
}

.StationDetailModal .StationTabContent {
  animation: slideUp 0.2s ease-out forwards;
  animation-duration: 0.2s !important;
}

.StationDetailModal .StationTop.Parking {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 18px 0 20px;
  padding: 0 16px;
}

.StationDetailModal .StationTop {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 34px 0;
  padding: 0 16px;
}
.StationDetailModal .StationTop div.topBar {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  margin-right: auto;
  margin-left: 12px;
  overflow: hidden;
}
.StationDetailModal .StationTop .StationLogo {
  position: relative;
  flex-direction: row;
  margin-right: 12px;
  overflow: inherit;
}
.StationDetailModal .StationTop .StationLogo div{
  display: flex;
}
.StationDetailModal .StationTop .StationLogo img {
  object-fit: cover;
  height: 40px;
  width: 40px;
  background-color: #fff;
  border: 2px solid var(--dt-border-light);
  border-radius: 50%;
  overflow: hidden;
}

.StationDetailModal .StationTop .StationLogo img + img {
  position: absolute;
  left: -7px;
}

.StationDetailModal .StationTop .StationName {
  display: flex;
  color: #fff !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  width: fit-content;
}

.StationDetailModal .StationTop .StationZoneCode {
  display: flex;
  background-color: var(--dt-pink);
  color: #fff !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
  padding: 1px 3px;
  border-radius: 3px;
}

.StationDetailModal .StationTop .StationDistance {
  color: var(--dt-secondary);
  font-size: 14px;
  font-weight: 600;
}

.StationDetailModal .StationTop .DirectionButtonWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 13px;
}

.StationTop .DirectionButtonWrapper .DirectionButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 44px;
  border-radius: 44px;
  border: 1px solid rgba(255, 255, 255, 0.5)
}

.StationDetailModal .StationTop .NotAllowedLabel{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 5px 8px;
  border-radius: 17px;
  white-space: nowrap;
  background-color: #e03232;
  width: fit-content !important;
  height: 33px;
  margin: 16px 0 0 0;
}

.StationDetailModal .StationTop .NotAllowedLabel .NotAllowedText {
  display: flex;
  color: #fff !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  width: 100%;
}
.StationDetailModal .StationTop .NotAllowedLabel .NotAllowedIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.SeeOfferButton {
  display: flex;
  align-items: center;
  height: 33px;
  padding: 0 10px 0 30px;
  border: none;
  border-radius: 33px;
  outline: none;
  background-color: var(--dt-yellow);
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.21173 10.2281C7.08215 10.1473 6.91785 10.1473 6.78827 10.2281L3.7238 12.1401C3.41882 12.3304 3.03716 12.0531 3.12388 11.7042L3.99529 8.19892C4.03214 8.0507 3.98137 7.89443 3.86443 7.79618L1.09908 5.47253C0.823868 5.24127 0.96965 4.7926 1.32823 4.76728L4.93125 4.51284C5.08361 4.50208 5.21654 4.4055 5.27385 4.26393L6.62923 0.915876C6.76412 0.582672 7.23588 0.582672 7.37077 0.915875L8.72615 4.26393C8.78346 4.4055 8.91639 4.50208 9.06875 4.51284L12.6718 4.76728C13.0303 4.7926 13.1761 5.24127 12.9009 5.47253L10.1356 7.79618C10.0186 7.89443 9.96786 8.0507 10.0047 8.19892L10.8761 11.7042C10.9628 12.0531 10.5812 12.3304 10.2762 12.1401L7.21173 10.2281Z' fill='%23353355'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 14px;
  color: var(--dt-primary);
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
}

.StationContent {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100vw;
  padding: 20px;
  border-radius: 38px;
  background-color: #fff;
  z-index: 1;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 100px;
}
.StationContentRow {
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: space-evenly;
}
.StationContentRow > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  text-align: center;
}
.StationContentRow > div > :first-child {
  height: 66px;
}

.StationContentRowCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0;
  transition: all 0.1s ease-out;
}
.StationContentRowCenter .icon {
  opacity: 0.5;
}

.StationContentRowBottom {
  display: flex;
  grid-column-gap: 8px;
  margin: 30px 0 0;
}
.StationContentRowBottom .button,
.StationContentRowBottom .DTButton,
.StationContentRowBottom .DTButtonOutlined,
.StationContentRowBottom .DTButtonOutlinedDark,
.StationContentRowBottom button {
  width: 50% !important;
}

.DTDirectionsButton {
  height: 55px !important;
  padding-left: 32px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.DTDirectionsButton .icon {
  position: relative;
  display: block;
  flex-shrink: 0;
  height: 14px;
  width: 14px;
  margin-right: 9px;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.19894 13.6647C8.99703 14.1358 8.49227 14.4051 7.98751 14.3041C7.48274 14.2031 7.11258 13.7657 7.11258 13.2273V7.3047H1.19001C0.685245 7.3047 0.247783 6.96819 0.14683 6.46342C0.0458768 5.95866 0.315085 5.45389 0.786198 5.25199L12.6313 0.406249C13.0352 0.237995 13.5063 0.338947 13.8091 0.641806C14.112 0.944665 14.2129 1.41578 14.0447 1.81959L9.19894 13.6647Z' fill='%23353355'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
}

.StationContentRowBottom,
.StationContentRowBottom * {
  animation: none !important;
}

/* Station Detail Modal Expanded */

.StationDetailModal .StationTop:not(.Parking) div {
  width: 100%;
}

.StationDetailModal .StationTop.Parking div:not(.StationLogo) {
  width: calc(100% - 52px);
}

.StationDetailModal .StationTop .SeeOfferButton {
  margin: 10px 0 0 40px;
}

.StationDetailModal .DTModalCloseButton {
  background-image: url("data:image/svg+xml,%3Csvg width='512' height='254' viewBox='0 0 512 254' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M503.523 13.1598C516.453 28.6762 514.357 51.7368 498.84 64.6671L284.095 243.621C267.82 257.184 244.18 257.184 227.905 243.622L13.1599 64.6671C-2.35651 51.7368 -4.4529 28.6762 8.47737 13.1598C21.4077 -2.35652 44.4682 -4.45293 59.9846 8.47737L256 171.824L452.015 8.47737C467.532 -4.45293 490.592 -2.35652 503.523 13.1598Z' fill='%23353355'/%3E%3C/svg%3E%0A");
  background-size: 14px;
  opacity: 0;
  visibility: hidden;
  transform: translate(100px);
  transition: all 0.1s ease-out;
}
.StationDetailModal .DTModalCloseButton {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.StationDetailModal .StationContent {
  border-radius: 38px 38px 0 0;
  min-height: 100%;
  margin-bottom: -16px;
}

.StationDetailModal .StationContentRowCenter {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.StationContentExpanded {
  display: none;
  flex-direction: column;
  margin: 30px 0 25px;
}
.StationDetailModal .StationContentExpanded {
  display: flex;
}

.StationTabsSlider {
  width: 100vw;
  margin-left: -20px;
  padding: 0 20px;
}

.StationTabContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: left;
}

.StationTabTop {
  display: flex;
  flex-direction: column;
}
.StationTabTop .StationTabTopLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 14px 0;
  border-bottom: 1px solid rgba(61, 60, 87, 0.15);
}
.StationTabTopLine.borderless{
  border-bottom: 0px;
  padding: 0px 0;
}
.StationTabTop .StationTabTopLine.ParkingCost {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
  padding: 14px 0;
  border-bottom: 1px solid rgba(61, 60, 87, 0.15);
}
.StationTabTop .StationTabTopLine > div:first-child {
  display: flex;
  align-items: center;
}
.StationTabTop .StationTabTopLine > div:last-child {
  display: flex;
  align-items: flex-start;
}

.OverstayFees {
  display: flex;
  align-items: center;
  width: 100%;
}
.StationTabContent .OverstayFees {
  margin: 25px 0 0;
}
.OverstayFee {
  display: flex;
  flex: 1 1 25%;
  flex-direction: column;
  align-items: center;
}
.OverstayFee span {
  color: var(--dt-primary);
}
.OverstayFee .OverstayDuration {
  font-size: 18px;
  font-weight: 600;
}
.OverstayFee .OverstayTemp {
  margin: 14px 0;
  display: block;
  height: 12px;
  width: 100%;
  background-color: #c0c0cb;
}
.OverstayFee:first-child .OverstayTemp {
  border-radius: 6px 0 0 6px;
}
.OverstayFee:last-child .OverstayTemp {
  border-radius: 0 6px 6px 0;
}
.OverstayFee .OverstayPrice {
  font-size: 11px;
  font-weight: 600;
}

.DTSegment {
  max-height: 42px;
  min-height: 42px;
  height: 42px;
  border-radius: 12px;
  background-color: #cfced6;
  color: #fff;
}
.DTSegment ::part(native) {
  max-height: 42px;
  min-height: 42px;
  height: 42px;
  border: none;
  border-radius: 12px;
  background-color: #cfced6;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0;
}
.DTSegment .segment-button-checked::part(native) {
  background-color: var(--dt-primary);
  margin-top: -2px;
}
.DTSegment ::part(indicator) {
  display: none;
}

.StationSocketTypesContainer{
  margin-bottom: 15%;
  margin-top: 10%;
}

.StationSocketTypes {
  display: flex;
  height: 110px;
  width: fit-content;
  border-radius: 14px;
  border: 1px solid rgba(61, 60, 87, 0.15);
}
.StationSocketType {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100px;
  height: 110px;
}
.StationSocketType:not(:last-of-type) {
  border-right: 1px solid rgba(61, 60, 87, 0.15);
}

.StationSocketTypeBullets {
  display: flex;
  font-weight: 600;
  font-size: 12px;
}
.StationSocketType span {
  color: var(--dt-primary-light);
  transition: all 0.1s ease-out;
}
.StationSocketType span.active {
  color: var(--dt-green);
}
.StationDetailModal .StationModalMore {
  display: none;
}
.NotificationsWrapper{
  position: absolute;
  top: 70px; 
  left: 16px;
  width: 100%;
}
.NotificationsWrapper.--oneView{
  width: calc(100vw - 32px);
}
.NotificationsWrapper .swiper{
  height: calc(100% - 55px);
  width: 100%;
}

.NotificationsWrapper .swiper .swiper-slide{
  display: unset;
}