

.MySiteCodes {
  width: 100%;
  height: 100%;
}
.SiteCode {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
}

.SiteCodes {
  width: 100%;
  height: calc(100% - 55px);
  .Codes {
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 61px);
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;
    padding-bottom: 40px;
    .Code {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 89px;
      border: 1px solid #c0c0cb;
      border-radius: 12px;
      padding: 16px;
    }
  }
  .AddSiteCodeButton {
    position: absolute;
    bottom: 44px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 32px);
    height: 55px;
    background-color: var(--dt-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 55px;
    gap: 9px;
  }
}
.SiteCodeDetail{
  .RemoveButton{
    position: absolute;
    left: 69.68%;
    right: 2.33%;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid rgba(61, 60, 87, 0.15);
    height: 43px;
    width: 96px;
    border-radius: 43px;
    color: var(--dt-primary);
    background-color: white;
  }
}
.SiteCodeAgreementText{
  color: var(--dt-primary);
  font-size: 14px;
  span {
    font-weight: 600;
    color: rgb(177, 29, 148);
  }
}

.SiteCodeContent .AddButton{
  font-weight: 600;
  font-size: 14px;
  border: 1px solid rgba(61, 60, 87, 0.15);
  border-radius: 43px;
  color: white;
  background-color: var(--dt-primary);
}

.ValidDurationTable {
  border-collapse: collapse;
  width: 100%;
  color: var(--dt-primary);
  margin-top: 16px;
}

.ValidDurationTable td, .ValidDurationTable th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
  text-transform: capitalize;
}
.ValidDurationTable td {
  text-transform: capitalize;
}
.ValidDurationTable tr:nth-child(even) {
  background-color: #dddddd;
}


.ParkinPermitModal h1{
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: var(--dt-primary);
    text-transform: capitalize;
    padding: 16px 0;
}
.ParkinPermitModal h2{
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: var(--dt-pink);
    padding: 10px 0;
}
.ParkinPermitModal p{
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: var(--dt-primary);
    padding: 8px 0;
    span.red-highlight {
            color: red;
        }
}
.ParkinPermitModal ul {
    list-style: none;
    color: var(--dt-primary);
    padding: 8px 0;
    li:before {
        content: "\2022";
        color: var(--dt-pink);
        font-size: 24px;
        margin-right: 8px;
    }
}
.ParkinPermitModal table{
    padding: 8px 0;
    tr{
        td:first-child{
            font-weight: 500;
        }
    }
    td{
        color: var(--dt-primary);
        padding: 8px;
    }
}

.signButton{
  width: calc(50% - 32px) !important;
  height: 40px;
  margin-top: 30px;
  background-color: var(--dt-primary);
  color: white;
  border-radius: 55px;
}