
.CardWrapper {
  display: inline-block;
  width: 335px;
  height: 200px;
  position: relative;
  top: 0;
  left: 0;
  .MobilePayments {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 20px;
    &.GooglePay{
      background: linear-gradient(0deg, #ECF1F8, #ECF1F8), #353355;
      .CardNumberText {
        color: rgba(95, 99, 104, .6) !important;
      }
    }
    &.ApplePay{
      background: linear-gradient(132deg, #292929 7.37%, #000000 95%), #353355;
      .CardNumberText {
        color: rgba(255, 255, 255, 1) !important
      }
    }
    .VisaLogo{
      position: absolute;
      right: 20px;
      top: 22px;
    }
    .BrandLogo{
      width: 105px;
      height: 43px;
      position: absolute;
      top: 58px;
      left: 21px;
    }
    .CardNumber {
      position: absolute;
      top: 114px;
      left: 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      .CardNumberText{
        border-bottom: 1px solid;
      }
    }
  }
  .Nordea{
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 20px;
    background: linear-gradient(137.76deg, #292FA5 2.23%, #111778 112.03%), #353355;
    color: rgba(255, 255, 255, .6) !important;
    .MastercardLogo{
      position: absolute;
      top: 16px;
      right: 20px;
    }
    .BrandLogo{
      position: absolute;
      top: 46px;
      left: 73px
    }
    .CardChip{
      position: absolute;
      top: 36px;
      left: 20px;
    }
    .CardNumber {
      position: absolute;
      top: 90px;
      left: 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      .CardNumberText{
        border-bottom: 1px solid;
      }
    }
    .CardHolderName {
      position: absolute;
      top: 137px;
      left: 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      .CardHolderNameText{
        border-bottom: 1px solid;
      }
    }
    .CardExpireDate {
      position: absolute;
      top: 137px;
      right: 20px;
      display: flex;
      align-items: center;
      gap: 5px;
      .CardExpireDateText{
        border-bottom: 1px solid;
      }
      .CardExpireDateSlash{
        color: white;
        font-size: larger;
        font-weight: 900;
      }
    }
  }
  .DefaultCard{
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 20px;
    background: linear-gradient(137.76deg, #292FA5 2.23%, #111778 112.03%), #353355;
    color: rgba(255, 255, 255, .6) !important;
    .MastercardLogo{
      position: absolute;
      top: 16px;
      right: 20px;
    }
    .VisaLogo{
      position: absolute;
      top: 16px;
      right: 20px;
    }
    .CardChip{
      position: absolute;
      top: 36px;
      left: 20px;
    }
    .CardNumber {
      position: absolute;
      top: 90px;
      left: 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      .CardNumberText{
        border-bottom: 1px solid;
      }
    }
    .CardHolderName {
      position: absolute;
      top: 137px;
      left: 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      .CardHolderNameText{
        border-bottom: 1px solid;
        text-transform: uppercase;
      }
    }
    .CardExpireDate {
      position: absolute;
      top: 137px;
      right: 20px;
      display: flex;
      align-items: center;
      gap: 5px;
      .CardExpireDateText{
        border-bottom: 1px solid;
      }
      .CardExpireDateSlash{
        color: white;
        font-size: larger;
        font-weight: 900;
      }
    }
  }
  .Paypal {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 20px;
    background: linear-gradient(132deg, #0F3EC2 7.37%, #0F3EC2 95%), #353355;
    color: rgba(255, 255, 255, .6) !important;
    .MastercardLogo{
      position: absolute;
      top: 16px;
      right: 20px;
    }
    .PaypalLogo{
      position: absolute;
      top: 47px;
      left: 145px;
      width: 94px;
      height: 112px;
    }
    .BrandLogo{
      width: 104px;
      height: 28px;
      position: absolute;
      top: 66px;
      left: 21px;
    }
    .CardNumber {
      position: absolute;
      top: 112px;
      left: 20px;
      display: flex;
      align-items: center;
      .CardNumberText{
        border-bottom: 1px solid;
      }
    }
  }
  .Spa {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 20px;
    background: linear-gradient(137.76deg, #409BCD 2.23%, #036BA4 114.39%), #353355;
    color: rgba(255, 255, 255, .6) !important;
    .MastercardLogo{
      position: absolute;
      top: 16px;
      right: 20px;
    }
    .BrandLogo{
      width: 90px;
      height: 32px;
      position: absolute;
      top: 66px;
      left: 21px;
    }
    .CardNumber {
      position: absolute;
      top: 112px;
      left: 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      .CardNumberText{
        border-bottom: 1px solid;
      }
    }
  }
  .DrifterCard{
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 20px;
    background: linear-gradient(137.76deg, #B11D94 2.23%, #353355 162.94%), #353355; 
    display: flex;
    justify-content: center;
    align-items: center;
    .DrifterMask{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url('../../assets/images/DrifterMask.png');
      z-index: 1000;
    }
    .DrifterCardChip{
      position: absolute;
      top: 38px;
      left: 20px;
    }
    .DrifterCardAddButton{
      width: 64px;
      height: 64px;
      border-radius: 64px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10000;
    }
  }
  
}