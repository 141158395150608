.FAQAccordion .DTAccordionContent ul{
    list-style: none;
    color: var(--dt-primary);
    padding: 8px 0;
}
.FAQAccordion .DTAccordionContent ul li:before {
    content: "\2022";
    color: var(--dt-pink);
    font-size: 24px;
    margin-right: 8px;
}